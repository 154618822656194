/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use sae columns.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type columns
 */
import { Column } from 'material-table';

/*
 * Used to type patients and institutions 
 */
import {
    IInstitution, usePatientsByCodes, MasterGroupContext, CollaboratingGroupContext, CountryContext, InstitutionContext, InstitutionsContext
} from '@ngt/opms';

import { Chip } from '@material-ui/core';

/*
 * Used to format datetimes
 */
import { DateTime } from 'luxon';



/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';

import FormContext from '@ngt/opms/dist/form/contexts/FormContext';

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

export const getOutcome = (outcome?: number) => {
    switch (outcome) {
        case Dtos.LookupOutcomeType.Recovered: { return 'Recovered/Resolved'; }
        case Dtos.LookupOutcomeType.Recovering: { return 'Recovering/Resolving'; }
        case Dtos.LookupOutcomeType.RecoveredWithSequelae: { return 'Recovered/Resolved with Sequelae'; }
        case Dtos.LookupOutcomeType.DeathOrUnknown: { return 'Death; Unknown'; }
        default: { return ''; }
    }
}

export const getRelationship = (relationship?: number) => {
    switch (relationship) {
        case Dtos.LookupRelationshipType.RelatedToIp: { return 'Related to IP'; }
        case Dtos.LookupRelationshipType.RelatedToStudyProcedure: { return 'Related to study procedure'; }
        case Dtos.LookupRelationshipType.Unrelated: { return 'Unrelated'; }
        default: { return ''; }
    }
}

export const getDetermination = (determination?: number) => {
    switch (determination) {
        case Dtos.LookupDeterminationType.Sar: { return 'SAR'; }
        case Dtos.LookupDeterminationType.Susar: { return 'SUSAR'; }
        default: { return ''; }
    }
}

const useSaeColumns = (summary: boolean, institutions?: Dtos.IInstitution[], patients?: Dtos.IPatient[]|null): Column<Dtos.SeriousAdverseEventForm>[] => {

    const columns = React.useMemo(() => {
        const c: (Column<Dtos.SeriousAdverseEventForm>|boolean)[] = [
            summary && {
                field: 'patientId',
                title: 'Participant Number',
                render: rowData => (
                    patients?.find(p => p.id == rowData.patientId)?.studyNumber
                )
            },
            summary && {
                field: 'patientId',
                title: 'Institution',
                render: rowData => (
                    <div
                        style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    >
                        {
                            institutions?.find(i => i.id == (patients?.find(p => p.id == rowData.patientId)?.institutionId))?.name
                        }
                    </div>
                )
            },
            {
                field: 'ctcaeTerm',
                title: 'CTCAE Term',
                render: rowData => (
                    (
                        <div
                            style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                        >
                            {rowData.ctcaeTerm}
                        </div>
                    )
                )
            },
            {
                field: 'eventOnset',
                title: 'Event Onset',
                render: rowData => (
                    rowData.eventOnset ? DateTime.fromISO(rowData.eventOnset).toFormat('dd/MM/yyyy') : null
                )
            },
            {
                field: 'eventCease',
                title: 'Event Ceased',
                render: rowData => (
                    rowData.eventCeased ? DateTime.fromISO(rowData.eventCeased).toFormat('dd/MM/yyyy') : null
                )
            },
            {
                field: 'outcome',
                title: 'Outcome',
                render: rowData => (
                    (
                        <div
                            style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                        >
                            {getOutcome(rowData.outcome)}
                        </div>
                    )
                )
            },
            {
                field: 'relationship',
                title: 'Relationship',
                render: rowData => (
                    (
                        <div
                            style={{ whiteSpace: 'normal', wordWrap: 'break-word'}}
                        >
                            {getRelationship(rowData.relationship)}
                        </div>
                    )
                )
            },
            {
                field: 'determinationBySponsor',
                title: 'Determination by Sponsor',
                render: rowData => (
                    getDetermination(rowData.determinationBySponsor)
                )
            },
            !summary && {
                field: 'reportedToTga',
                title: 'Reported to TGA',
                render: rowData => (
                    rowData.reportedToTga ? DateTime.fromISO(rowData.reportedToTga).toFormat('dd/MM/yyyy') : null
                )
            },
            !summary && {
                field: 'reportedToHrec',
                title: 'Reported to HREC',
                render: rowData => (
                    rowData.reportedToHrec ? DateTime.fromISO(rowData.reportedToHrec).toFormat('dd/MM/yyyy') : null
                )
            }
        ];

        return c.filter(column => !!column) as Column<Dtos.SeriousAdverseEventForm>[];
    }, [patients, institutions, summary]);

    return columns;
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useSaeColumns;