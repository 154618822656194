/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the invitation page component
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

import { useParams } from 'react-router';

import {
    Theme,
    makeStyles,
    Typography,
    CardMedia,
    Grid,
    Paper,
    AppBar,
    List,
    ListItem,
    ListItemText,    Accordion,
    AccordionSummary,
    AccordionDetails,
    Link,
    styled,
    AccordionProps,
    AccordionSummaryProps,
    ListItemIcon,
    TextField,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Button
} from '@material-ui/core';
import { useIsMobile, Field, Text, Form, useSnackbar, OnlinePatientManagementContext  } from '@ngt/opms';
import { faChevronRight } from '@fortawesome/pro-duotone-svg-icons/faChevronRight';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons/faChevronDown';
import { faCircle as faCircleSolid } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { faCircle as faCircleRegular } from '@fortawesome/pro-regular-svg-icons/faCircle';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons/faCheckCircle';
import { faExclamationCircle } from '@fortawesome/pro-duotone-svg-icons/faExclamationCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm, SubmitHandler } from "react-hook-form";

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */
import * as Dtos from '../../api/dtos';
import trialPlanImage from '../../assets/img/trial-plan.png';
import trialTimeLineImage from '../../assets/img/trial-timeline.png';
import trialProcedureImage from '../../assets/img/trial-procedure.jpg';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { StatusTypeEnum } from '../../api/dtos';
import { DateTime } from 'luxon';
import Alert from '@material-ui/lab/Alert';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IInvitationPageProps {
}

interface IInvitationPageParams {
    guid: string
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    container: {
        padding: theme.spacing(3, 0),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        }
    },
    title: {
        color: theme.palette.secondary.main,
        marginBottom: '1rem'
    },
    subtitle: {
        color: theme.palette.primary.main,
        fontSize: '2rem',
        marginTop: '1.5rem',
        marginBottom: '1rem',
        fontWeight: 'bold'
    },
    p: {
        marginBottom: '1rem'
    },
    cardMedia: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        height: '100%'
    },
    cardMediaContainer: {
        width: '100%',
        paddingTop: '56.25%',
        position: 'relative'
    },
    quickAccess: {
        display: 'flex',
        backgroundColor: 'white'
    },
    quickAccessHeader: {
        display: 'flex',
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        padding: '1rem',
        fontSize: '1.25rem'
    },
    quickAccessBody: {
        display: 'flex',
        backgroundColor: 'white',
        color: theme.palette.secondary.main,
        fontSize: '1.0rem'
    },
    quickAccessSummary: {
        display: 'flex',
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        fontSize: '1.25rem'
    },
    link: {
        color: theme.palette.secondary.main,
        cursor: 'pointer',
    },
    accordions: {
        marginTop: '1rem'
    },
    accordionSummary: {
        color: theme.palette.primary.main,
        fontSize: '2rem'
    },
    listImage: {
        width: '100%',
        height: 'auto'
    },
    eoiGridItem: {
        fontSize: '1rem',
        display: 'flex',
        flexDirection: 'column',

        justifyContent: 'center'

    },
    eoiGrid: {
        '& .MuiGrid-item': {
            padding: theme.spacing(0, 2)
        }
    },
    eoiSubmit: {
        borderRadius: 15,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: 'white'
        }
    },
    error: {
        color: '#f44336'
    },
    submitSuccess: {
        backgroundColor: "#ece7f1",
        color: theme.palette.primary.main,
        margin: theme.spacing(3, 0),
        padding: theme.spacing(5),
    },
    submitSuccessMessage: {
        fontSize: '2rem',
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    submitSuccessIcon: {
        color: theme.palette.secondary.main,
        fontSize: '4rem'
    },
    errorMessage: {
        color: 'red',
        padding: '0.15rem',
        fontSize: '0.85rem'
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const CustomAccordion = styled((props: AccordionProps) => (
    <Accordion elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:before': {
        display: 'none',
    }
}));

const CustomAccordionSummary = styled((props: AccordionSummaryProps) => (
    <AccordionSummary
        expandIcon={<FontAwesomeIcon icon={faChevronRight} />}
        {...props}
    />
))(({ theme }) => ({
    flexDirection: 'row-reverse',
    '&  .MuiAccordionSummary-expandIcon': {
        color: theme.palette.primary.main,
    },
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
    '& .MuiTypography-body1': {
        color: theme.palette.primary.main,
        fontSize: '1.5rem'
    },
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const EoiField: React.FunctionComponent<{
    label?: string,
    inputComponent: React.ReactNode
}> = ({
    label,
    inputComponent
}) => {
    const classes = useStyles();

    return (
        <>
            {
                !!label && <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={2}
                    xl={2}
                    className={classes.eoiGridItem}
                >
                    {label}
                </Grid>
            }
            <Grid
                item
                xs={12}
                sm={12}
                md={!!label ? 8 : 12}
                lg={!!label ? 10 : 12}
                xl={!!label ? 10 : 12}
                className={classes.eoiGridItem}
            >
                {inputComponent}
            </Grid>
        </>    
    );
    }

const ErrorMessage: React.FunctionComponent<{ message: string }> = ({ message }) => {
    const classes = useStyles();

    return (
        <div className={classes.errorMessage}>
            <FontAwesomeIcon icon={faExclamationCircle} />
            <span style={{ marginLeft: "0.5rem" }}>{message}</span>
        </div>
    );
}

const EoiForm: React.FunctionComponent<{
    setEoiSubmitted: React.Dispatch<React.SetStateAction<boolean>>
}> = ({
    setEoiSubmitted
}) => {
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const client = onlinePatientManagement.serviceStackClient;

    const params = useParams<IInvitationPageParams>();

    const invitationGuid = params?.guid;


    const onSubmit = React.useCallback((data) => {

        const eoi: Dtos.Eoi = new Dtos.Eoi({
            firstName: data.firstName,
            lastName: data.lastName,
            confirmed: data.confirmed,
            dateSubmitted: DateTime.local().toUTC().toString(),
            statusId: StatusTypeEnum.Waiting
        });

        client
            .post(new Dtos.EoiPostSave({
                eoi: eoi,
                invitationGuid: invitationGuid
            }))
            .then(response => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Expression of Interest Submitted
                        </AlertTitle>
                        The expression of interest was submitted successfully.
                    </>,
                    { variant: 'success' }
                );
                setEoiSubmitted(true);
            })
            .catch((e) => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Expression of Interest Not Submitted
                        </AlertTitle>
                        {e.responseStatus.message}
                    </>,
                    { variant: 'critical' }
                );
            })
    }, [client, enqueueSnackbar]);

    const { register, handleSubmit, watch, formState: { errors, isSubmitting } } = useForm<Dtos.Eoi>();

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid
                    container
                    spacing={2}
                    className={classes.eoiGrid}
                >
                    <EoiField
                        label={"First Name"}
                        inputComponent={
                            <>
                                <TextField
                                    label="First name"
                                    variant="outlined"
                                    color="primary"
                                    margin="dense"
                                    fullWidth
                                    {...register("firstName", { required: true })}
                                />
                                {errors.firstName && <ErrorMessage message={"This field is required"}/>}
                            </>
                        }
                    />
                    <EoiField
                        label={"Last Name"}
                        inputComponent={
                            <>
                                <TextField
                                    label="Last name"
                                    variant="outlined"
                                    color="primary"
                                    margin="dense"
                                    fullWidth
                                    {...register("lastName", { required: true })}
                                />
                                {errors.lastName && <ErrorMessage message={"This field is required"} />}
                            </>
                        }
                    />
                    <EoiField
                        inputComponent={
                            <>
                                <FormGroup style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '1rem' }}>
                                    <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                {...register("confirmed", { required: true })}
                                            />
                                        }
                                        label="I confirm that I have read and understood the information on this page"
                                    />
                                        {errors.confirmed && <ErrorMessage message={"This field is required"} />}
                                    </div>
                                </FormGroup>
                            </>
                        }
                    />
                </Grid>
                
                <Button
                    type="submit"
                    variant="outlined"
                    color="primary"
                    className={classes.eoiSubmit}
                    disabled={isSubmitting}
                    fullWidth
                >
                    Submit
                </Button>
            </form>
        </>    
    );
}

const InvitationPage: React.FunctionComponent<IInvitationPageProps> = () => {
    const classes = useStyles();

    const isMobile = useIsMobile();

    const [expanded, setExpanded] = React.useState('');

    const handleChange = (panel: string) => (event: any, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : '');
    };

    const params = useParams<IInvitationPageParams>();

    const invitationGuid = params?.guid;

    const picDownloadLink = `/opms/invitation/download/picf/${invitationGuid}`;

    const [eoiSubmitted, setEoiSubmitted] = React.useState(false);

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const client = onlinePatientManagement.serviceStackClient;

    const [picfVersion, setPicfVersion] = React.useState<string | undefined>();

    const [institutionName, setInstitutionName] = React.useState<string | undefined>();

    const { enqueueSnackbar } = useSnackbar();

    const onPicDownloadClick = React.useCallback(() => {
        client
            .get(new Dtos.InvitationGetPicf({
                guid: invitationGuid
            }))
            .then(response => {
                var link = document.createElement('a');
                link.href = picDownloadLink
                link.click();
                link.remove();

                setPicfVersion(response?.version);
            })
            .catch((e) => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Error
                        </AlertTitle>
                        An error occurred while trying to download the PIC file. Please contact your system administrator.
                    </>,
                    { variant: 'critical' }
                );
            })
    }, [enqueueSnackbar, invitationGuid, setPicfVersion, picDownloadLink]);

    //load the patient informed consent using the guid in the url
    React.useEffect(() => {
        client
            .get(new Dtos.InvitationGetPicf({
                guid: invitationGuid
            }))
            .then(response => {
                setPicfVersion(response.version);
                setInstitutionName(response.institutionName);
            })
            .catch((e) => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Error
                        </AlertTitle>
                        An error occurred while trying to retrieve the PIC file. Please contact your system administrator.
                    </>,
                    { variant: 'critical' }
                );
            });
    }, [client, enqueueSnackbar, invitationGuid, setPicfVersion, setInstitutionName]);

    return (
        <>
            <div
                className={classes.container}
            >
                <Typography
                    variant="h1"
                    className={classes.title}
                >
                    <strong>The FINER Trial</strong> <br />
                    BCT 2101/CCTG MA.40 FINER
                </Typography>
                <Grid
                    container
                    spacing={5}
                >
                    {
                        !!isMobile && <Grid
                            item
                            xs={12}
                            md={4}
                            style={{ paddingBottom: 0 }}
                        >
                            <AppBar
                                position="sticky"
                                className={classes.quickAccess}
                            >
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<FontAwesomeIcon icon={faChevronDown} color="white" />}
                                        aria-controls="panel0a-content"
                                        id="panel0a-header"
                                        className={classes.quickAccessSummary}
                                    >
                                        <Typography>Quick Access</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <List>
                                            <ListItem component={Link} href={"#finerVideo"} className={classes.link}>
                                                <ListItemText primary="FINER Information Video" />
                                            </ListItem>
                                            <ListItem component={Link} href={"#finerPurpose"} className={classes.link}>
                                                <ListItemText primary="What is the Purpose of FINER?" />
                                            </ListItem>
                                            <ListItem component={Link} href={"#finerToDo"} className={classes.link}>
                                                <ListItemText primary="What Do I Need to Do First?" />
                                            </ListItem>
                                            <ListItem component={Link} href={"#finerInvolve"} className={classes.link}>
                                                <ListItemText primary="What Does Taking Part in FINER Involve?" />
                                            </ListItem>
                                            <ListItem component={Link} href={"#finerPlan"} className={classes.link}>
                                                <ListItemText primary="FINER Trial Plan" />
                                            </ListItem>
                                            <ListItem component={Link} href={"#finerGlossary"} className={classes.link}>
                                                <ListItemText primary="FINER Glossary of Terms" />
                                            </ListItem>
                                            <ListItem component={Link} href={"#finerProcedures"} className={classes.link}>
                                                <ListItemText primary="FINER Trial Procedures" />
                                            </ListItem>
                                            <ListItem component={Link} href={"#finerTimeline"} className={classes.link}>
                                                <ListItemText primary="FINER Timeline" />
                                            </ListItem>
                                            <ListItem component={Link} href={"#finerNextSteps"} className={classes.link}>
                                                <ListItemText primary="Next Steps" />
                                            </ListItem>
                                            <ListItem component={Link} href={"#finerEoi"} className={classes.link}>
                                                <ListItemText primary="Expression of Interest" />
                                            </ListItem>
                                            <ListItem component={Link} href={"#finerResources"} className={classes.link}>
                                                <ListItemText primary="Resources" />
                                            </ListItem>
                                        </List>
                                    </AccordionDetails>
                                </Accordion>
                            </AppBar>
                        </Grid>
                    }
                    <Grid
                        item
                        xs={12}
                        md={8}
                    >
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            This page and the video are summaries of the FINER study and what happens if you decide to take part. Take as much time as you need to decide about taking part. Talk to your family, whanau, friends, and/or family doctor about taking part. Feel free to ask any questions of the study doctor and study team at any time.
                        </Typography>  
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            The FINER study is for people with advanced or metastatic breast cancer of a particular type - ER-positive and HER2-negative.
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            You have previously had treatment with drugs like ribociclib, palbociclib or abemaciclib, and letrozole, anastrozole or exemestane, and the treatments have stopped being effective.
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            To understand more about the FINER trial and what is involved in taking part, click on the headings below to expand the information. There is also further information on the Resources section of this page.
                        </Typography>
                        <Typography
                            variant="h2"
                            className={classes.subtitle}
                            id="finerVideo"
                        >
                            FINER Information Video
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            You may like to take notes of any questions or concerns you have while watching the video or reading this page to ask your study doctor and study team. 
                        </Typography>
                        <div className={classes.cardMediaContainer}>
                            <CardMedia
                                component="iframe"
                                src="https://www.youtube.com/embed/oZJeCFWSKvI"
                                className={classes.cardMedia}
                            />
                        </div>
                        <div className={classes.accordions}>
                            <CustomAccordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} id="finerPurpose">
                                <CustomAccordionSummary aria-controls="panel1d-content" id="panel1d-header" className={classes.accordionSummary}>
                                    <Typography>What is the Purpose of FINER?</Typography>
                                </CustomAccordionSummary>
                                <CustomAccordionDetails>
                                    <List>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FontAwesomeIcon icon={faCircleSolid} />
                                            </ListItemIcon>
                                            <ListItemText primary="The drug ipatasertib can slow the growth and survival of cancer through the PI3K-AKT-mTOR pathway in cancer cells." />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FontAwesomeIcon icon={faCircleSolid} />
                                            </ListItemIcon>
                                            <ListItemText primary="Researchers are trying to find out in FINER whether cancer will remain under control for longer in patients treated with ipatasertib and fulvestrant, compared with those treated with the standard treatment of fulvestrant on its own." />
                                        </ListItem>
                                    </List>
                                </CustomAccordionDetails>
                            </CustomAccordion>
                            <CustomAccordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} id="finerToDo">
                                <CustomAccordionSummary aria-controls="panel2d-content" id="panel2d-header" className={classes.accordionSummary}>
                                    <Typography>What Do I Need to Do First?</Typography>
                                </CustomAccordionSummary>
                                <CustomAccordionDetails>
                                    <List>
                                        <ListItem>
                                            <ListItemText primary="To take part in the FINER study, you will need to talk with a study doctor about the study, have all your questions answered, and sign the Consent section of the full Participant Information Sheet/Consent Form." />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary="If you don’t wish to take part in this study, you don’t have to. If you decide to take part, you can stop at any time if you change your mind later. If you do not take part you will still receive the best possible care as per the current standards for breast cancer treatment." />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText>
                                                All trials must be registered on a publicly accessible database. Further information on FINER can also be found on the <Link href="https://clinicaltrials.gov/ct2/show/NCT04650581" target="_blank" rel="noopener" className={classes.link}>public registry</Link>.
                                        </ListItemText>
                                        </ListItem>
                                    </List>
                                </CustomAccordionDetails>
                            </CustomAccordion>
                            <CustomAccordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} id="finerInvolve">
                                <CustomAccordionSummary aria-controls="panel3d-content" id="panel3d-header" className={classes.accordionSummary}>
                                    <Typography>What Does Taking Part in FINER Involve?</Typography>
                                </CustomAccordionSummary>
                                <CustomAccordionDetails>
                                    <List>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FontAwesomeIcon icon={faCircleSolid} />
                                            </ListItemIcon>
                                            <ListItemText primary="If you decide to take part in FINER you will be asked to sign a Consent Form after reviewing the study information, and before starting any study procedures" />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FontAwesomeIcon icon={faCircleSolid} />
                                            </ListItemIcon>
                                            <ListItemText primary="You will be randomly assigned to one of two possible study groups (Arm A or Arm B):" />
                                        </ListItem>
                                        <ListItem style={{ paddingLeft: '4rem' }}>
                                            <ListItemIcon>
                                                <FontAwesomeIcon icon={faCircleRegular} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <u>Arm A</u>: You will take Ipatasertib tablets once per day with water for 21 out of 28 days, plus have a fulvestrant injection into the muscle of each buttock on day 1 and day 15 of Cycle 1, day 1 of Cycle 2, and then every 28 days.
                                        </ListItemText>
                                        </ListItem>
                                        <ListItem style={{ paddingLeft: '4rem' }}>
                                            <ListItemIcon>
                                                <FontAwesomeIcon icon={faCircleRegular} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <u>Arm B</u>: You will take placebo tablets once per day with water for 21 out of 28 days, plus have a fulvestrant injection into the muscle of each buttock on day 1 and day 15 of Cycle 1, day 1 of Cycle 2, and then every 28 days.
                                        </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FontAwesomeIcon icon={faCircleSolid} />
                                            </ListItemIcon>
                                            <ListItemText primary="You will have study visits:" />
                                        </ListItem>
                                        <ListItem style={{ paddingLeft: '4rem' }}>
                                            <ListItemIcon>
                                                <FontAwesomeIcon icon={faCircleRegular} />
                                            </ListItemIcon>
                                            <ListItemText primary="Before you start study treatment" />
                                        </ListItem>
                                        <ListItem style={{ paddingLeft: '4rem' }}>
                                            <ListItemIcon>
                                                <FontAwesomeIcon icon={faCircleRegular} />
                                            </ListItemIcon>
                                            <ListItemText primary="Day 1 & Day 15 of Cycle 1 (Week 1 & Week 3)" />
                                        </ListItem>
                                        <ListItem style={{ paddingLeft: '4rem' }}>
                                            <ListItemIcon>
                                                <FontAwesomeIcon icon={faCircleRegular} />
                                            </ListItemIcon>
                                            <ListItemText primary="Day 1 of Cycle 2 (Week 5) and then every 4 weeks during treatment with the study drugs" />
                                        </ListItem>
                                        <ListItem style={{ paddingLeft: '4rem' }}>
                                            <ListItemIcon>
                                                <FontAwesomeIcon icon={faCircleRegular} />
                                            </ListItemIcon>
                                            <ListItemText primary="4 weeks after the end of the last cycle of study treatment and then every 12 weeks" />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FontAwesomeIcon icon={faCircleSolid} />
                                            </ListItemIcon>
                                            <ListItemText primary="At each study visit:" />
                                        </ListItem>
                                        <ListItem style={{ paddingLeft: '4rem' }}>
                                            <ListItemIcon>
                                                <FontAwesomeIcon icon={faCircleRegular} />
                                            </ListItemIcon>
                                            <ListItemText primary="You will have a physical examination, routine blood tests and check you are taking your study treatment correctly" />
                                        </ListItem>
                                        <ListItem style={{ paddingLeft: '4rem' }}>
                                            <ListItemIcon>
                                                <FontAwesomeIcon icon={faCircleRegular} />
                                            </ListItemIcon>
                                            <ListItemText primary="Other tests such as scans, research blood tests and questionnaires will also be done at some study visits" />
                                        </ListItem>
                                        <ListItem style={{ paddingLeft: '4rem' }}>
                                            <ListItemIcon>
                                                <FontAwesomeIcon icon={faCircleRegular} />
                                            </ListItemIcon>
                                            <ListItemText primary="Discuss with the study team any issues related to the cancer or the study treatment" />
                                        </ListItem>
                                    </List>
                                </CustomAccordionDetails>
                            </CustomAccordion>
                            <CustomAccordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} id="finerPlan">
                                <CustomAccordionSummary aria-controls="panel4d-content" id="panel4d-header" className={classes.accordionSummary}>
                                    <Typography>FINER Trial Plan</Typography>
                                </CustomAccordionSummary>
                                <CustomAccordionDetails>
                                    <List className={classes.listImage}>
                                        <ListItem className={classes.listImage}>
                                            <img
                                                className={classes.listImage}
                                                src={trialPlanImage}
                                                alt={"FINER Trial Plan"}
                                            />
                                        </ListItem>
                                    </List>
                                </CustomAccordionDetails>
                            </CustomAccordion>
                            <CustomAccordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} id="finerGlossary">
                                <CustomAccordionSummary aria-controls="panel5d-content" id="panel5d-header" className={classes.accordionSummary}>
                                    <Typography>FINER Glossary of Terms</Typography>
                                </CustomAccordionSummary>
                                <CustomAccordionDetails>
                                    <List>
                                        <ListItem>
                                            <ListItemText>
                                                Please refer to the  <Link href="https://www.breastcancertrials.org.au/file/6636/Finer-Glossary" target="_blank" rel="noopener" className={classes.link}>Glossary</Link> for further information.
                                        </ListItemText>
                                        </ListItem>
                                    </List>
                                </CustomAccordionDetails>
                            </CustomAccordion>
                            <CustomAccordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} id="finerProcedures">
                                <CustomAccordionSummary aria-controls="panel6d-content" id="panel6d-header" className={classes.accordionSummary}>
                                    <Typography>FINER Trial Procedures</Typography>
                                </CustomAccordionSummary>
                                <CustomAccordionDetails>
                                    <List className={classes.listImage}>
                                        <ListItem className={classes.listImage}>
                                            <img
                                                className={classes.listImage}
                                                src={trialProcedureImage}
                                                alt={"FINER Trial Procedures"}
                                            />
                                        </ListItem>
                                    </List>
                                </CustomAccordionDetails>
                            </CustomAccordion>
                            <CustomAccordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')} id="finerTimeline">
                                <CustomAccordionSummary aria-controls="panel7d-content" id="panel7d-header" className={classes.accordionSummary}>
                                    <Typography>FINER Timeline</Typography>
                                </CustomAccordionSummary>
                                <CustomAccordionDetails>
                                    <List className={classes.listImage}>
                                        <ListItem className={classes.listImage}>
                                            <img
                                                className={classes.listImage}
                                                src={trialTimeLineImage}
                                                alt={"FINER Timeline"}
                                            />
                                        </ListItem>
                                    </List>
                                </CustomAccordionDetails>
                            </CustomAccordion>
                        </div>
                        <Typography
                            variant="h2"
                            className={classes.subtitle}
                            id="finerNextSteps"
                        >
                            Next Steps
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            Please review the full Participant Information Sheet/Consent Form to make sure you have all the information you need to make a decision.
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            Please complete the information in the Expression of Interest form below. You will be contacted by the study doctor or the study team at your hospital to discuss taking part in FINER.
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            The study doctor and study team will answer any of your questions or concerns about FINER, and help you understand the study procedures so you can decide if you want to take part.
                        </Typography> 
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            Please ask as many questions as you would like before agreeing to take part in the FINER Trial. You can also withdraw your consent at any time.
                        </Typography>
                        <Typography
                            variant="h2"
                            className={classes.subtitle}
                            id="finerEoi"
                        >
                            Expression of Interest
                        </Typography>
                        {
                            !eoiSubmitted && <EoiForm setEoiSubmitted={setEoiSubmitted}/>
                        }
                        {
                            !!eoiSubmitted && <div className={classes.submitSuccess}>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={2}
                                        lg={1}
                                        xl={1}
                                        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                    >
                                        <FontAwesomeIcon icon={faCheckCircle} className={classes.submitSuccessIcon} />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={10}
                                        lg={11}
                                        xl={11}
                                    >
                                        <div className={classes.submitSuccessMessage}>
                                            <span>Thank you for completing <br /> the Expression of Interest form</span>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        }
                    </Grid>
                    {
                        !isMobile && <Grid
                            item
                            xs={12}
                            md={4}
                        >
                            <AppBar
                                position="sticky"
                                className={classes.quickAccess}
                            >
                                <div
                                    className={classes.quickAccessHeader}
                                >
                                    Quick Access
                                </div>
                                <div
                                    className={classes.quickAccessBody}
                                >
                                    <List>
                                        <ListItem component={Link} href={"#finerVideo"} className={classes.link}>
                                            <ListItemText primary="FINER Information Video" />
                                        </ListItem>
                                        <ListItem component={Link} href={"#finerPurpose"} className={classes.link}>
                                            <ListItemText primary="What is the Purpose of FINER?" />
                                        </ListItem>
                                        <ListItem component={Link} href={"#finerToDo"} className={classes.link}>
                                            <ListItemText primary="What Do I Need to Do First?" />
                                        </ListItem>
                                        <ListItem component={Link} href={"#finerInvolve"} className={classes.link}>
                                            <ListItemText primary="What Does Taking Part in FINER Involve?" />
                                        </ListItem>
                                        <ListItem component={Link} href={"#finerPlan"} className={classes.link}>
                                            <ListItemText primary="FINER Trial Plan" />
                                        </ListItem>
                                        <ListItem component={Link} href={"#finerGlossary"} className={classes.link}>
                                            <ListItemText primary="FINER Glossary of Terms" />
                                        </ListItem>
                                        <ListItem component={Link} href={"#finerProcedures"} className={classes.link}>
                                            <ListItemText primary="FINER Trial Procedures" />
                                        </ListItem>
                                        <ListItem component={Link} href={"#finerTimeline"} className={classes.link}>
                                            <ListItemText primary="FINER Timeline" />
                                        </ListItem>
                                        <ListItem component={Link} href={"#finerNextSteps"} className={classes.link}>
                                            <ListItemText primary="Next Steps" />
                                        </ListItem>
                                        <ListItem component={Link} href={"#finerEoi"} className={classes.link}>
                                            <ListItemText primary="Expression of Interest" />
                                        </ListItem>
                                        <ListItem component={Link} href={"#finerResources"} className={classes.link}>
                                            <ListItemText primary="Resources" />
                                        </ListItem>
                                    </List>
                                </div>
                            </AppBar>
                        </Grid>
                    }
                </Grid>
                <div>
                    <Typography
                        variant="h2"
                        className={classes.subtitle}
                        id="finerResources"
                    >
                        Resources
                    </Typography>
                    <Typography
                        variant="body1"
                        variantMapping={{
                            body1: 'p'
                        }}
                        className={classes.p}
                    >
                        This study is being conducted by <Link href="https://www.breastcancertrials.org.au/home" target="_blank" rel="noopener" className={classes.link}>Breast Cancer Trials (BCT)</Link>
                        and the <Link href="https://www.ctg.queensu.ca/" target="_blank" rel="noopener" className={classes.link}>Canadian Cancer Trials Group (CCTG)</Link>.
                    </Typography>
                    <Typography
                        variant="body1"
                        variantMapping={{
                            body1: 'p'
                        }}
                        className={classes.p}
                    >
                        <Link href="https://www.breastcancertrials.org.au/file/6738/Finer-trial-summary" target="_blank" rel="noopener" className={classes.link}>FINER trial summary</Link> - click to download or print a summary of the FINER trial
                    </Typography>
                    <Typography
                        variant="body1"
                        variantMapping={{
                            body1: 'p'
                        }}
                        className={classes.p}
                    >
                        <Link href="https://www.breastcancertrials.org.au/file/6739/Finer-Tissue-banking" target="_blank" rel="noopener" className={classes.link}>Tissue Banking</Link> is a common part of many clinical trials, including FINER. It is optional, meaning that you will be given a separate information and consent form about tissue banking in the FINER study. You don’t have to agree to tissue banking if you don’t want to.
                    </Typography>
                    <Typography
                        variant="body1"
                        variantMapping={{
                            body1: 'p'
                        }}
                        className={classes.p}
                    >
                        <Link href="https://www.breastcancertrials.org.au/what-is-a-clinical-trial" target="_blank" rel="noopener" className={classes.link}>What is a Clinical Trial?</Link>
                    </Typography>
                    <Typography
                        variant="body1"
                        variantMapping={{
                            body1: 'p'
                        }}
                        className={classes.p}
                    >
                        <Link href="https://www.breastcancertrials.org.au/breast-cancer" target="_blank" rel="noopener" className={classes.link}>What is Breast Cancer?</Link>
                    </Typography>
                    <Typography
                        variant="body1"
                        variantMapping={{
                            body1: 'p'
                        }}
                        className={classes.p}
                    >
                        <Link onClick={onPicDownloadClick} target="_blank" rel="noopener" className={classes.link}>Participant Informed Consent (PIC) {picfVersion} {institutionName ? (institutionName) : ""}</Link>
                    </Typography>
                    <Typography
                        variant="body1"
                        variantMapping={{
                            body1: 'p'
                        }}
                        className={classes.p}
                        style={{ fontSize: '0.9rem', fontStyle: 'italic' }}
                    >
                        Ethics approval must be obtained for clinical trials involving human participation. HREC has approved the FINER Trial HREC Reference.
                    </Typography>
                    <Typography
                        variant="body1"
                        variantMapping={{
                            body1: 'p'
                        }}
                        className={classes.p}
                        style={{ fontSize: '0.9rem', fontStyle: 'italic' }}
                    >
                        The FINER Trial and participant materials have been endorsed by the BCT Consumer Advisory Panel.
                    </Typography>
                    <Typography
                        variant="body1"
                        variantMapping={{
                            body1: 'p'
                        }}
                        className={classes.p}
                        style={{ fontSize: '0.9rem', fontStyle: 'italic' }}
                    >
                        Participating in FINER will contribute to clinical research, impacting future clinical research, standard practice for treatment, and future breast cancer patients.
                    </Typography>
                </div>
            </div>
        </>  
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default InvitationPage;