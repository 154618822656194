/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    CrfForm,
    Field,
    Text,
    DatePicker,
    Select,
    ToggleButtonGroup,
    Condition,
    CrfCondition,
    ICrfConditionParameters,
    GetFieldLookupItem,
    FieldGroup,
    FormBreadcrumbs,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading,
    FormContext,
    Password,
    FormsByCodesResolver,
    InstitutionsByIdsResolver,
    ALL_INSTITUTIONS_CODE,
    InstitutionsContext,
    IForm,
    FormsContext,
    CollapsibleTable,
    InstitutionBreadcrumbs,
    TrialContextSelector,
    CountryContext,
    usePatientsByCodes,
    useFormsByCodes,
    useSnackbar,
    IInstitution,
    IPatient
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../api/dtos';
import { Typography, makeStyles, Button } from '@material-ui/core';
import { FormSummaryTable, FormDialog } from './PatientSummary';
import useTransferColumns from '../hooks/useTransferColumns';
import DialogFormResolver, { ICrfFormMappingProps } from '../components/resolver/DialogFormResolver';
import TransferForm from './form/TransferForm';
import { useHistory, useParams } from 'react-router-dom';
import SeriousAdverseEventForm from './form/SeriousAdverseEventForm';
import useSaeColumns from '../hooks/useSaeColumns';
import { Column } from 'material-table';
import PatientEventResolver from '../components/resolver/PatientEventResolver';
import { table } from 'console';
import Form from '@ngt/opms/dist/form/Form';
import { Institution, Patient } from '../api/dtos';
import { AlertTitle } from '@material-ui/lab';
import { useEffect } from 'react';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface ISaeSummaryProps {
}

interface ISaeSummaryTableProps {
    onRowClick: any
}

interface ISaeSummaryParams {
    institutionCode?: string
    patientStudyNumber?: string
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    tableContainer: {
        padding: theme.spacing(3, 0, 0),
        position: 'relative'
    },
    padding: {
        padding: theme.spacing(3)
    },
    dialogContainer: {
        position: 'absolute',
        zIndex: 100,
        top: '10%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */


/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsAdminister,
    Dtos.Permission.OpmsPatientView,
];

const SaeFormDialog: React.FunctionComponent<ICrfFormMappingProps> = ({ open, setOpen, mode, loading }) => {
    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [, , formsActions] = useFormsByCodes("serious-adverse-event-form", null, "enrollment", 1, false);

    const [[canUpdatePatient, canViewPatient], permissionLoadState] = usePermissionsByIds(permissions, null, null, null, null, false);

    return (
        <FormDialog
            open={open}
            setOpen={setOpen}
            form={
                <SeriousAdverseEventForm
                    exitForm={() => setOpen(false)}
                    mode={mode}
                    disable={!canUpdatePatient}
                    afterFormSave={formsActions.load}
                />
            }
            loading={loading}
            width="md"
        />
    );
}

const formMapping = {
    "serious-adverse-event-form": {
        component: SaeFormDialog
    }
}

const patientStates = [Dtos.PatientStateType.Enrolled];

const SaeSummaryTable: React.FunctionComponent<ISaeSummaryTableProps> = ({
    onRowClick
}) => {
    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { country } = React.useContext(CountryContext);
    const { institutions } = React.useContext(InstitutionsContext);

    const { institutionCode } = useParams<ISaeSummaryParams>();

    const parsedInstitutionCode = React.useMemo(() => {
        return institutionCode !== ALL_INSTITUTIONS_CODE ? institutionCode : null;
    }, [institutionCode]);

    const institution = React.useMemo(() => {
        return institutions?.find(institution => !!institution.code && institution.code === parsedInstitutionCode);
    }, [parsedInstitutionCode, institutions]);

    const [patients, patientLoadState] = usePatientsByCodes(masterGroup?.code, collaboratingGroup?.code, country?.code, institution?.code, patientStates, false);

    const columns = useSaeColumns(true, institutions ?? [], patients);

    const { forms: untypedForms, loadState: formsLoadState } = React.useContext(FormsContext);

    const formsLoading = formsLoadState.state === RequestState.None || formsLoadState.state === RequestState.Pending;

    const formsToUse = React.useMemo(() => {
        return untypedForms?.filter(f => (!institution && patients?.find(p => p.id === f.patientId)) || (patients?.filter(p => p.institutionId === institution?.id)?.find(p => p.id === f.patientId)))
    }, [institution, patients, untypedForms]);

    const pageOptions = React.useMemo(() => {
        return {
            pageSize: 20,
            pageSizeOptions: [20]
        }
    }, [formsToUse])

    if (patientLoadState.state == RequestState.None || patientLoadState.state == RequestState.Pending ) {
        return (
            <RouteLoading />
        );
    }

    return (
        <>
            <CollapsibleTable
                data={formsToUse ?? []}
                title={"Serious Adverse Events"}
                pluralizeTitle={false}
                entityName={"record"}
                loading={formsLoading}
                columns={columns as Column<IForm>[]}
                onRowClick={onRowClick}
                options={pageOptions as any}
            />
        </>
    );
}

const SaeSummary: React.FunctionComponent<ISaeSummaryProps> = () => {
    const classes = useStyles();
    
    const { enqueueSnackbar } = useSnackbar();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { country } = React.useContext(CountryContext);
    const { institutions } = React.useContext(InstitutionsContext);

    const { institutionCode } = useParams<ISaeSummaryParams>();

    const parsedInstitutionCode = React.useMemo(() => {
        return institutionCode !== ALL_INSTITUTIONS_CODE ? institutionCode : null;
    }, [institutionCode]);

    const institution = React.useMemo(() => {
        return institutions?.find(institution => !!institution.code && institution.code === parsedInstitutionCode);
    }, [parsedInstitutionCode, institutions]);

    const institutionsToUse = React.useMemo(() => {
        return institutions?.filter(i => i.code);
    }, [institutions]);

    const [patients] = usePatientsByCodes(masterGroup?.code, collaboratingGroup?.code, country?.code, institution?.code, patientStates, true);

    const [saePatientId, setSaePatientId] = React.useState<number | undefined>(undefined);
    const [patientId, setPatientId] = React.useState<number | undefined>(undefined);

    const { patient, institution: patientInstitution }= React.useMemo(() => {
        const patient = patients?.find(p => p.id === saePatientId);
        const institution = institutions?.find(i => i.id === patient?.institutionId)
        console.log("patient", patient)
        console.log("institution", institution)

        return { patient, institution }
    }, [patients, saePatientId]);

    const [[canUpdatePatient, canViewPatient], permissionLoadState] = usePermissionsByIds(permissions, null, null, null, null, true); // filter the sae by user institution in the backend

    const history = useHistory();

    React.useEffect(() => {
        if (permissionLoadState.state !== RequestState.Pending &&
            permissionLoadState.state !== RequestState.None) {
            if (!canViewPatient) {
                history.replace(`/registration`);
            }
        }
    }, [canViewPatient, permissionLoadState, history]);

    const [saeDialogOpen, setSaeDialogOpen] = React.useState(false);

    const [saeRepeat, setSaeRepeat] = React.useState<number | undefined>(undefined);
    const [repeat, setRepeat] = React.useState<number | undefined>(undefined);

    const onSaeRowClick = React.useCallback(
    (event: React.MouseEvent<Element, MouseEvent>, rowData?: Dtos.IForm | undefined) => {

            setSaePatientId(rowData?.patientId);
            setSaeRepeat(rowData?.repeat);
            setSaeDialogOpen(true);

    }, [setSaeDialogOpen, setSaeRepeat, setSaePatientId]);

    const onSelect = React.useCallback((newMasterGroupCode?: string | null, newCollaboratingGroupCode?: string | null, newCountryCode?: string | null, newInstitutionCode?: string | null) => {
        if (newInstitutionCode) {
            history.push(`/sae/${newInstitutionCode}`);
        }
        else {
            history.push(`/sae`);
        }

        return;
    }, [history]);

    

    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [patientInstitutionDialog, setPatientInstitutionDialog] = React.useState<IInstitution | undefined>(undefined);;

    // this created a break between the values being set to open the dialog and the dialog actually being open.
    // this break is used to check if the patients institution is set to disabled. before when the patients intitution was set to disabled it caused infinate loading
    // this instead catches that before opening the dialog and if its not disabled then sets all the dialog values because if even one is
    // set before this then the infinite loading pops up.
    useEffect(() => {
        if (saeDialogOpen == true)
        {
            if (patientInstitution != undefined)
            {
                if (patientInstitution.enabled == true)
                {
                    setPatientInstitutionDialog(patientInstitution);
                    setPatientId(saePatientId);
                    setRepeat(saeRepeat);
                    setDialogOpen(true);
                    setSaeDialogOpen(false);
                }
                else
                {
                    enqueueSnackbar(
                        <>
                            <AlertTitle>
                                Error
                            </AlertTitle>
                            The institution this patient is connected to is no longer available.
                        </>,
                        { variant: 'critical' }
                    );
                    setDialogOpen(false);
                    setSaeDialogOpen(false);
                }
            }
            else{
                setDialogOpen(false);
                setSaeDialogOpen(false);
            }
        }
    }, [saeDialogOpen, saePatientId, saeRepeat, patientInstitution, setDialogOpen, setRepeat, setPatientId])

    //if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
    //    return (
    //        <RouteLoading />
    //    );
    //}

    return (
        <>
            <InstitutionBreadcrumbs />

            <div
                className={classes.container}
            >
                <TrialContextSelector
                    onChange={onSelect}
                    allowAllMasterGroups={true}
                    allowAllCollaboratingGroups={true}
                    allowAllCountries={true}
                    allowAllInstitutions={true}
                    hideMasterGroups={true}
                    hideCollaboratingGroups={true}
                    hideCountries={true}
                    masterGroupCode={masterGroup?.code}
                    collaboratingGroupCode={collaboratingGroup?.code}
                    countryCode={country?.code}
                    institutionCode={institution?.code}
                    institutions={institutionsToUse}
                />

                <div
                    className={classes.tableContainer}
                    style={{position: 'relative'}}
                >
                    <FormsByCodesResolver
                        formDefinitionIdentifier={"serious-adverse-event-form"}
                        patientStudyNumber={null}
                        eventDefinitionCode={"enrollment"}
                        eventRepeat={1}
                        resolveBeforeLoad={true}
                    >
                        <SaeSummaryTable
                            onRowClick={canUpdatePatient ? onSaeRowClick : undefined} // (patient, patientInstitution)
                        />
                    </FormsByCodesResolver>

                    <div
                        className={classes.dialogContainer}
                    >
                        {
                            !!patient && !!patientInstitutionDialog && (
                                <PatientEventResolver
                                    institutionCode={patientInstitutionDialog.code}
                                    patientStudyNumber={patient.studyNumber}
                                    eventDefinitionCode={"enrollment"}
                                    eventRepeat={1}
                                    resolveBeforeLoad
                                >
                                    <DialogFormResolver
                                    open={
                                        
                                        // maybe make a new value that is constantly being updated based on this value plus the if (institutionsIn?.enabled == true)
                                        // If not set saeDialog


                                        dialogOpen}
                                    setOpen={setDialogOpen}
                                    mode={"save"}
                                    institutionCode={(patient as Patient).institutionCode}
                                    patientStudyNumber={patient.studyNumber}
                                    eventDefinitionCode={"enrollment"}
                                    eventRepeat={1}
                                    formDefinitionCode={"serious-adverse-event-form"}
                                    formRepeat={repeat}
                                    formMapping={formMapping}
                                    resolveBeforeLoad
                                    />
   
                                </PatientEventResolver>
                            )
                        
                        }
                    </div>
                </div>
            </div>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default SaeSummary;
