/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently selected event.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type request states.
 */
import { RequestState } from '@ngt/request-utilities';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */

/*
 * Used to pass event context to the rest of the app.
 */
import { useEventDefinitionByCode, usePatientByStudyNumber, PatientContext, useInstitutionByCode, useInstitutionById, useCountryByCode, useCountryById, CountryContext, useCollaboratingGroupByCode, useCollaboratingGroupById, CollaboratingGroupContext, useMasterGroupByCode, useMasterGroupById, MasterGroupContext, useEventByCodes, EventDefinitionContext, InstitutionContext, EventContext, RouteLoading } from '@ngt/opms';


/*
 * Used to show loading view.
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export interface ICrfFormMapping {
    components: React.ReactNode;
}

export interface IPatientEventResolverProps {
    masterGroupCode?: string | null;
    collaboratingGroupCode?: string | null;
    countryCode?: string | null;
    institutionCode?: string | null;
    patientStudyNumber?: string | null;
    eventDefinitionCode?: string | null;
    eventRepeat?: number | null;
    createPatient?: boolean | null;
    resolveBeforeLoad?: boolean | null;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const PatientEventResolver: React.FunctionComponent<IPatientEventResolverProps> = ({
    masterGroupCode,
    collaboratingGroupCode,
    countryCode,
    institutionCode,
    patientStudyNumber,
    eventDefinitionCode,
    eventRepeat,
    createPatient,
    resolveBeforeLoad,
    children
}) => {

    if (!createPatient && !patientStudyNumber) {
        throw new Error('Route Invalid: Patient creation not enabled and no study number was provided.');
    }

    if (!patientStudyNumber && !institutionCode) {
        throw new Error('Route Invalid: No institution code was provided (required for new patient creation).');
    }

    if (!eventDefinitionCode) {
        throw new Error('Route Invalid: No event definition code has been provided.');
    }

    if (!eventRepeat) {
        throw new Error('Route Invalid: No event repeat has been provided.')
    }

    // Event Definition Loading.
    const [eventDefinition, eventDefinitionLoadState, eventDefinitionActions] = useEventDefinitionByCode(eventDefinitionCode, true);

    const eventDefinitionContext = React.useMemo(() => {
        return {
            eventDefinition,
            loadState: eventDefinitionLoadState,
            actions: eventDefinitionActions
        };
    }, [eventDefinition, eventDefinitionLoadState, eventDefinitionActions]);

    // Form Definition Loading.

    let conditionalContexts: React.ReactNode = children;


    // Patient Loading
    const loadPatient = !!patientStudyNumber;

    const [patient, patientLoadState, patientSaveState, patientActions] = usePatientByStudyNumber(patientStudyNumber as any, loadPatient);

    const patientContext = React.useMemo(() => {
        return {
            patient,
            loadState: patientLoadState,
            saveState: patientSaveState,
            actions: patientActions
        };
    }, [patient, patientLoadState, patientActions]);

    if (loadPatient) {
        conditionalContexts = (
            <PatientContext.Provider value={patientContext}>
                {conditionalContexts}
            </PatientContext.Provider>
        );
    }

    // Load institution
    const loadInstitutionByCode = !!institutionCode;
    const loadInstitutionById = !!patientContext.patient?.institutionId && !institutionCode;

    const [institutionByCode, institutionByCodeLoadState, institutionByCodeActions] = useInstitutionByCode(institutionCode as any, loadInstitutionByCode);
    const [institutionById, institutionByIdLoadState, institutionByIdActions] = useInstitutionById(patientContext.patient?.institutionId as any, loadInstitutionById);

    const institutionContext = React.useMemo(() => {
        return {
            institution: loadInstitutionByCode ?
                institutionByCode :
                institutionById,
            loadState: loadInstitutionByCode ?
                institutionByCodeLoadState :
                institutionByIdLoadState,
            actions: loadInstitutionByCode ?
                institutionByCodeActions :
                institutionByIdActions
        };
    }, [institutionByCode, institutionByCodeLoadState, institutionByCodeActions, institutionById, institutionByIdLoadState, institutionByIdActions, loadInstitutionByCode]);

    // load Country
    const loadCountryByCode = !!countryCode;
    const loadCountryById = !!institutionContext.institution?.countryId && !countryCode;

    const [countryByCode, countryByCodeLoadState, countryByCodeActions] = useCountryByCode(countryCode as any, loadCountryByCode);
    const [countryById, countryByIdLoadState, countryByIdActions] = useCountryById(institutionContext.institution?.countryId as any, loadCountryById);

    const countryContext = React.useMemo(() => {
        return {
            country: loadCountryByCode ?
                countryByCode :
                countryById,
            loadState: loadCountryByCode ?
                countryByCodeLoadState :
                countryByIdLoadState,
            actions: loadCountryByCode ?
                countryByCodeActions :
                countryByIdActions
        };
    }, [countryByCode, countryByCodeLoadState, countryByCodeActions, countryById, countryByIdLoadState, countryByIdActions, loadCountryByCode]);

    if (loadCountryById || loadCountryByCode) {
        conditionalContexts = (
            <CountryContext.Provider value={countryContext}>
                {conditionalContexts}
            </CountryContext.Provider>
        );
    }

    // load CollaboratingGroup
    const loadCollaboratingGroupByCode = !!collaboratingGroupCode;
    const loadCollaboratingGroupById = !!institutionContext.institution?.collaboratingGroupId && !collaboratingGroupCode;

    const [collaboratingGroupByCode, collaboratingGroupByCodeLoadState, collaboratingGroupByCodeActions] = useCollaboratingGroupByCode(collaboratingGroupCode as any, loadCollaboratingGroupByCode);
    const [collaboratingGroupById, collaboratingGroupByIdLoadState, collaboratingGroupByIdActions] = useCollaboratingGroupById(institutionContext.institution?.collaboratingGroupId as any, loadCollaboratingGroupById);

    const collaboratingGroupContext = React.useMemo(() => {
        return {
            collaboratingGroup: loadCollaboratingGroupByCode ?
                collaboratingGroupByCode :
                collaboratingGroupById,
            loadState: loadCollaboratingGroupByCode ?
                collaboratingGroupByCodeLoadState :
                collaboratingGroupByIdLoadState,
            actions: loadCollaboratingGroupByCode ?
                collaboratingGroupByCodeActions :
                collaboratingGroupByIdActions
        };
    }, [collaboratingGroupByCode, collaboratingGroupByCodeLoadState, collaboratingGroupByCodeActions, collaboratingGroupById, collaboratingGroupByIdLoadState, collaboratingGroupByIdActions, loadCollaboratingGroupByCode]);

    if (loadCollaboratingGroupById || loadCollaboratingGroupByCode) {
        conditionalContexts = (
            <CollaboratingGroupContext.Provider value={collaboratingGroupContext}>
                {conditionalContexts}
            </CollaboratingGroupContext.Provider>
        );
    }

    // load MasterGroup
    const loadMasterGroupByCode = !!masterGroupCode;
    const loadMasterGroupById = !!institutionContext.institution?.masterGroupId && !masterGroupCode;

    const [masterGroupByCode, masterGroupByCodeLoadState, masterGroupByCodeActions] = useMasterGroupByCode(masterGroupCode as any, loadMasterGroupByCode);
    const [masterGroupById, masterGroupByIdLoadState, masterGroupByIdActions] = useMasterGroupById(institutionContext.institution?.masterGroupId as any, loadMasterGroupById);

    const masterGroupContext = React.useMemo(() => {
        return {
            masterGroup: loadMasterGroupByCode ?
                masterGroupByCode :
                masterGroupById,
            loadState: loadMasterGroupByCode ?
                masterGroupByCodeLoadState :
                masterGroupByIdLoadState,
            actions: loadMasterGroupByCode ?
                masterGroupByCodeActions :
                masterGroupByIdActions
        };
    }, [masterGroupByCode, masterGroupByCodeLoadState, masterGroupByCodeActions, masterGroupById, masterGroupByIdLoadState, masterGroupByIdActions, loadMasterGroupByCode]);

    if (loadMasterGroupById || loadMasterGroupByCode) {
        conditionalContexts = (
            <MasterGroupContext.Provider value={masterGroupContext}>
                {conditionalContexts}
            </MasterGroupContext.Provider>
        );
    }

    // load event
    const createEvent = true;

    const loadEvent = !!institutionContext.institution?.code && (!loadPatient || !!patientContext.patient?.studyNumber);

    const [event, eventLoadState, eventSaveState, eventActions] = useEventByCodes(institutionContext.institution?.code, patientContext.patient?.studyNumber, eventDefinitionCode, eventRepeat, createPatient, createEvent, loadEvent);

    const eventContext = React.useMemo(() => {
        return {
            event,
            loadState: eventLoadState,
            saveState: eventSaveState,
            actions: eventActions
        };
    }, [event, eventLoadState, eventActions]);


    const notLoading =
        eventDefinitionContext.loadState.state !== RequestState.None &&
        eventDefinitionContext.loadState.state !== RequestState.Pending &&
        (!loadPatient ||
            (patientContext.loadState.state !== RequestState.None &&
                patientContext.loadState.state !== RequestState.Pending)) &&
        institutionContext.loadState.state !== RequestState.None &&
        institutionContext.loadState.state !== RequestState.Pending &&
        ((!loadCountryById && !loadCountryByCode) ||
            (countryContext.loadState.state !== RequestState.None &&
                countryContext.loadState.state !== RequestState.Pending)) &&
        ((!loadCollaboratingGroupById && !loadCollaboratingGroupByCode) ||
            (collaboratingGroupContext.loadState.state !== RequestState.None &&
                collaboratingGroupContext.loadState.state !== RequestState.Pending)) &&
        ((!loadMasterGroupById && !loadMasterGroupByCode) ||
            (masterGroupContext.loadState.state !== RequestState.None &&
                masterGroupContext.loadState.state !== RequestState.Pending)) &&
        eventContext.loadState.state !== RequestState.None &&
        eventContext.loadState.state !== RequestState.Pending;

    return (
        <EventDefinitionContext.Provider value={eventDefinitionContext}>
            <InstitutionContext.Provider value={institutionContext}>
                <EventContext.Provider value={eventContext}>
                    {
                        !resolveBeforeLoad || notLoading ?
                            conditionalContexts :
                            <RouteLoading />
                    }
                </EventContext.Provider>
            </InstitutionContext.Provider>
        </EventDefinitionContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default PatientEventResolver;