/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use status columns.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type columns
 */
import { Column } from 'material-table';

/*
 * Used to type patients and institutions 
 */
import {
    IInstitution
} from '@ngt/opms';

import { Chip } from '@material-ui/core';

/*
 * Used to format datetimes
 */
import { DateTime } from 'luxon';



/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';
import FormContext from '@ngt/opms/dist/form/contexts/FormContext';

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

export const getStatus = (status?: number) => {
    switch (status) {
        case Dtos.LookupStatusType.Enrolled: { return 'Enrolled'; }
        case Dtos.LookupStatusType.Surgery: { return 'Surgery'; }
        case Dtos.LookupStatusType.EndOfTreatment: { return 'End of Treatment'; }
        case Dtos.LookupStatusType.FollowUpYear2: { return 'Follow-up Year 2'; }
        case Dtos.LookupStatusType.FollowUpYear4: { return 'Follow-up Year 4'; }
        case Dtos.LookupStatusType.EndOfStudy: { return 'End of Study'; }
        default: { return ''; }
    }
}

const useStatusColumns = (): Column<Dtos.StatusForm>[] => {

    const columns = React.useMemo(() => {
        const c: (Column<Dtos.StatusForm>)[] = [
            {
                field: 'status',
                title: 'Status',
                render: rowData => (
                    getStatus(rowData.status)
                ),
                width: 210
            },
            {
                field: 'date',
                title: 'Date',
                render: rowData => (
                    rowData.date ? DateTime.fromISO(rowData.date).toFormat('dd/MM/yyyy') : null
                ),
                width: 210
            }
        ];

        return c.filter(column => !!column) as Column<Dtos.StatusForm>[];
    }, []);

    return columns;
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useStatusColumns;