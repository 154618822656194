/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use status columns.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type columns
 */
import { Column } from 'material-table';

/*
 * Used to type patients and institutions 
 */
import {
    IInstitution, InstitutionsContext, PatientContext
} from '@ngt/opms';

import { Chip, Select, Typography } from '@material-ui/core';

/*
 * Used to format datetimes
 */
import { DateTime } from 'luxon';



/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';

import FormContext from '@ngt/opms/dist/form/contexts/FormContext';

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useTransferColumns = (): Column<Dtos.StatusForm>[] => {

    const { institutions } = React.useContext(InstitutionsContext);

    const { patient } = React.useContext(PatientContext);

    const columns = React.useMemo(() => {
        const c: (Column<Dtos.TransferForm>)[] = [
            {
                field: 'dateNotified',
                title: 'Date Notified',
                render: rowData => (
                    rowData.dateNotified ? DateTime.fromISO(rowData.dateNotified).toFormat('dd/MM/yyyy') : null
                ),
                width: 210
            },
            {
                field: 'dateReported',
                title: 'Date Reported',
                render: rowData => (
                    rowData.dateReported ? DateTime.fromISO(rowData.dateReported).toFormat('dd/MM/yyyy') : null
                ),
                width: 210
            },
            {
                field: 'institutionId',
                title: 'New Site',
                render: rowData => {
                    return (
                        <>
                            {institutions?.find(i => i.id === rowData.institutionId)?.name} {patient?.institutionId === rowData.institutionId && <Typography variant="overline" color="secondary">(current)</Typography>}
                        </>    
                    );
                },
                width: 210
            },
            {
                field: 'datePicIcSigned',
                title: 'Date PIS/IC Signed',
                render: rowData => (
                    rowData.datePicIcSigned ? DateTime.fromISO(rowData.datePicIcSigned).toFormat('dd/MM/yyyy') : null
                ),
                width: 210
            },
            {
                field: 'dateReferred',
                title: 'Date Referred',
                render: rowData => (
                    rowData.dateReferred ? DateTime.fromISO(rowData.dateReferred).toFormat('dd/MM/yyyy') : null
                ),
                width: 210
            },
            {
                field: 'dateConfirmed',
                title: 'Date Confirmed',
                render: rowData => (
                    rowData.dateConfirmed ? DateTime.fromISO(rowData.dateConfirmed).toFormat('dd/MM/yyyy') : null
                ),
                width: 210
            },
            {
                field: 'firstFollowUp',
                title: 'First Follow-up',
                render: rowData => (
                    rowData.firstFollowUp ? DateTime.fromISO(rowData.firstFollowUp).toFormat('dd/MM/yyyy') : null
                ),
                width: 210
            }
        ];

        return c.filter(column => !!column) as Column<Dtos.TransferForm>[];
    }, [institutions, patient]);

    return columns;
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useTransferColumns ;