/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    CrfForm,
    Field,
    Text,
    DatePicker,
    Select,
    ToggleButtonGroup,
    Condition,
    CrfCondition,
    ICrfConditionParameters,
    GetFieldLookupItem,
    FieldGroup,
    FormBreadcrumbs,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading,
    FormContext,
    Password,
    FormsByCodesResolver,
    InstitutionsByIdsResolver,
    ALL_INSTITUTIONS_CODE,
    InstitutionsContext
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../api/dtos';
import { Typography, makeStyles, Button } from '@material-ui/core';
import { FormSummaryTable, FormDialog } from './PatientSummary';
import useTransferColumns from '../hooks/useTransferColumns';
import DialogFormResolver, { ICrfFormMappingProps } from '../components/resolver/DialogFormResolver';
import TransferForm from './form/TransferForm';
import { useHistory, useParams } from 'react-router-dom';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IPatientTransferProps {
}

interface IPatientTransferParams {
    institutionCode?: string
    patientStudyNumber?: string
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    tableContainer: {
        padding: theme.spacing(0, 3, 9),
    },
    padding: {
        padding: theme.spacing(3)
    },
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */


/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsAdminister,
    Dtos.Permission.OpmsPatientView,
];

const TransferFormDialog: React.FunctionComponent<ICrfFormMappingProps> = ({ open, setOpen, mode, loading }) => {
    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdatePatient, canViewPatient], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, false);

    return (
        <FormDialog
            open={open}
            setOpen={setOpen}
            form={
                <TransferForm
                    exitForm={() => setOpen(false)}
                    mode={mode}
                    disable={!canUpdatePatient}
                />
            }
            loading={loading}
            width="md"
        />
    );
}

const formMapping = {
    "transfer-form": {
        component: TransferFormDialog
    }
}

const PatientTransfer: React.FunctionComponent<IPatientTransferProps> = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);
    const { form } = React.useContext(FormContext);

    const [[canUpdatePatient, canViewPatient], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true);

    const history = useHistory();

    React.useEffect(() => {
        if (permissionLoadState.state !== RequestState.Pending &&
            permissionLoadState.state !== RequestState.None) {
            if (!canViewPatient) {
                history.replace(`/registration`);
            }
        }
    }, [canViewPatient, permissionLoadState, history]);

    const transferColumns = useTransferColumns();

    const [transferDialogOpen, setTransferDialogOpen] = React.useState(false);

    const [transferRepeat, setTransferRepeat] = React.useState<number | undefined>(undefined);

    const [transferFormMode, setTransferFormMode] = React.useState<"save" | "remove">("save");

    const onTransferRowClick = React.useCallback((event: React.MouseEvent<Element, MouseEvent>, rowData?: Dtos.IForm | undefined) => {
        setTransferRepeat(rowData?.repeat);
        setTransferDialogOpen(true);
        setTransferFormMode("save");
    }, [setTransferDialogOpen, setTransferRepeat, setTransferFormMode]);

    const onTransferAddClick = React.useCallback(() => {
        setTransferRepeat(undefined);
        setTransferDialogOpen(true);
        setTransferFormMode("save");
    }, [setTransferDialogOpen, setTransferRepeat, setTransferFormMode]);

    const onTransferRemoveClick = React.useCallback((event: React.MouseEvent<Element, MouseEvent>, rowData?: Dtos.IForm | undefined) => {
        event.stopPropagation();
        setTransferRepeat(rowData?.repeat);
        setTransferDialogOpen(true);
        setTransferFormMode("remove");
    }, [setTransferDialogOpen, setTransferRepeat, setTransferFormMode]);

    const { institutionCode } = useParams<IPatientTransferParams>();

    const { institutions } = React.useContext(InstitutionsContext);

    const onBackClick = React.useCallback(() => {
        history.push(`/registration/${institutionCode}/${patient?.studyNumber}`)
    }, [history, institution, patient]);

    React.useEffect(() => {
        //if an institution is selected and the patient institution is updated, update the selected institution
        const patientInstitutionCode = institutions?.find(i => i.id === patient?.institutionId)?.code;

        if (institutionCode !== ALL_INSTITUTIONS_CODE && institutionCode !== patientInstitutionCode) {
            history.push(`/registration/${patientInstitutionCode}/${patient?.studyNumber}/transfer`);
        }

    }, [history, institutionCode, institutions, patient]);

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <>
            <FormBreadcrumbs />
            <Typography
                className={classes.padding}
                color="secondary"
                variant="h1"
            >
                Patient Transfer
            </Typography>

            <div
                className={classes.tableContainer}
            >
                <FormsByCodesResolver
                    formDefinitionIdentifier={"transfer-form"}
                    patientStudyNumber={patient?.studyNumber}
                    eventDefinitionCode={"enrollment"}
                    eventRepeat={1}
                    resolveBeforeLoad={true}
                >
                    <FormSummaryTable
                        title="Transfer Records"
                        columns={transferColumns}
                        onRowClick={onTransferRowClick}
                        onAddClick={onTransferAddClick}
                        onRemoveClick={onTransferRemoveClick}
                        backButton={
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={onBackClick}
                            >
                                Back
                            </Button>
                        }
                    />
                </FormsByCodesResolver>

                <DialogFormResolver
                    open={transferDialogOpen}
                    setOpen={setTransferDialogOpen}
                    mode={transferFormMode}
                    institutionCode={institution?.code}
                    patientStudyNumber={patient?.studyNumber}
                    eventDefinitionCode={"enrollment"}
                    eventRepeat={1}
                    formDefinitionCode={"transfer-form"}
                    formRepeat={transferRepeat}
                    formMapping={formMapping}
                    resolveBeforeLoad
                />
            </div>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default PatientTransfer;
