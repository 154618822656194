/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    CrfForm,
    Field,
    KeyboardDatePicker,
    Select,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    FormContext,
    EventDefinitionContext,
    EventContext,
    ProgressButton,
    OnlinePatientManagementContext,
    useSnackbar,
    useFormByCodes,
    CrfDialogForm
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-bctapi';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';
import { makeStyles } from '@material-ui/core';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { useHistory } from 'react-router-dom';
import { RequestState } from '@ngt/request-utilities';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IFormProps {
    exitForm: () => void;
    mode: "save" | "remove",
    disable?: boolean
}

interface ITransferFormParams {
    institutionCode?: string
    patientStudyNumber?: string
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    buttonGroup: {
        padding: theme.spacing(1,3),
        textAlign: 'center',

        '& > *': {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
        },

        [theme.breakpoints.up('sm')]: {
            textAlign: 'right',
            '& > *': {
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(0)
            }
        }
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */



/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsAdminister,
    Dtos.Permission.OpmsPatientView,
];

const TransferForm: React.FunctionComponent<IFormProps> = ({
    exitForm,
    mode,
    disable
}) => {
    const classes = useStyles();

    const { institution } = React.useContext(InstitutionContext);
    const { patient, actions: patientActions } = React.useContext(PatientContext);
    const { eventDefinition } = React.useContext(EventDefinitionContext);
    const { event } = React.useContext(EventContext);
    const { form, actions: formActions } = React.useContext(FormContext);

    const afterSave = React.useCallback(() => {
        exitForm();
        patientActions.load(); //reloads the patient as the institution might have changed

        if (!form?.id) {
            formActions.clear();
        }
    }, [form, formActions, exitForm, patientActions]);

    const saveMode = React.useMemo(() => {
        return mode === "save";
    }, [mode]);

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const client = onlinePatientManagement?.serviceStackClient;

    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = React.useState(false);

    const onRemoveClick = React.useCallback(() => {

        setLoading(true);

        client
            .delete(new Dtos.TransferFormDeleteWithId({
                id: form?.id
            }))
            .then(response => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Removed
                        </AlertTitle>
                        Transfer record has been removed successfully.
                    </>,
                    { variant: 'success' }
                );

                exitForm();
                setLoading(false);
                patientActions.load();
            })
            .catch((e) => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Not Removed
                        </AlertTitle>
                        An error occurred while trying to remove the transfer record.
                    </>,
                    { variant: 'critical' }
                );
                setLoading(false);
                exitForm();
            })
    }, [client, enqueueSnackbar, exitForm, form, setLoading, patientActions]);

    return (
        <> 
            <CrfDialogForm
                formType={Dtos.TransferForm}
                validateOn="onChange"
                canEdit={!disable && saveMode}
                onCancel={exitForm}
                afterFormSave={afterSave}
                hideButtons={!saveMode}
                dialogTitle={!!saveMode ? undefined : 'Remove Transfer Record'}
            >
                <Field
                    name="dateNotified"
                    component={KeyboardDatePicker}
                    md={4}
                    lg={5}
                    xl={5}
                />
                <Field
                    name="dateReported"
                    component={KeyboardDatePicker}
                    md={4}
                    lg={5}
                    xl={5}
                />
                <Field
                    name="institutionId"
                    component={Select}
                    md={4}
                    lg={5}
                    xl={5}
                />
                <Field
                    name="datePicIcSigned"
                    component={KeyboardDatePicker}
                    md={4}
                    lg={5}
                    xl={5}
                />
                <Field
                    name="dateReferred"
                    component={KeyboardDatePicker}
                    md={4}
                    lg={5}
                    xl={5}
                />
                <Field
                    name="dateConfirmed"
                    component={KeyboardDatePicker}
                    md={4}
                    lg={5}
                    xl={5}
                />
                <Field
                    name="firstFollowUp"
                    component={KeyboardDatePicker}
                    md={4}
                    lg={5}
                    xl={5}
                />
                {
                    !saveMode && (
                        <div
                            className={classes.buttonGroup}>
                            <ProgressButton
                                loading={loading}
                                variant="text"
                                color="secondary"
                                onClick={exitForm}
                            >
                                Cancel
                            </ProgressButton>
                            <ProgressButton
                                loading={loading}
                                variant="text"
                                color="primary"
                                onClick={onRemoveClick}
                            >
                                Remove
                            </ProgressButton>
                        </div>    
                    )
                }
            </CrfDialogForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default TransferForm;
