/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the trial consent page component
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

import {
    Theme,
    makeStyles,
    Typography,
    List,
    ListItem,
    Link,
    ListItemIcon,
    Grid,
    Button,
    FormGroup,
    FormControlLabel,
    Checkbox,
    TextField,
    ListItemText,
    RadioGroup,
    Radio,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-duotone-svg-icons/faAngleRight';
import { faExclamationCircle } from '@fortawesome/pro-duotone-svg-icons/faExclamationCircle';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons/faCheckCircle';
import { useSnackbar, OnlinePatientManagementContext, useAuthenticatedUser, ProgressButton, RouteLoading } from '@ngt/opms';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import MuiPhoneNumber from "material-ui-phone-number";

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */
import * as Dtos from '../../api/dtos';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { DateTime } from 'luxon';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface ITrialConsentPageProps {
    
}

interface ITrialConsentPageParams {
    guid: string
}

interface IPatientConsentFormProps {
    isInvestigator: boolean,
    patientConsent: Dtos.PatientConsent,
    picfVersion: string,
    setConsentSubmitted: React.Dispatch<React.SetStateAction<boolean>>
}

interface IPatientConsentFieldProps {
    fieldGroupLabel?: string,
    label?: string,
    inputComponent: React.ReactNode
}

interface IPatientConsentVerificationDialogProps {
    open: boolean,
    patientConsent?: Dtos.PatientConsent,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setConsentSubmitted: React.Dispatch<React.SetStateAction<boolean>>
}


/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    container: {
        padding: theme.spacing(3, 0),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        }
    },
    title: {
        color: theme.palette.secondary.main,
        fontSize: '2rem',
        fontWeight: 'bold',
        marginBottom: '1.25rem',
        textAlign: 'center'
    },
    subtitle: {
        color: theme.palette.primary.main,
        fontSize: '1.25rem',
        fontWeight: 'bold',
        marginTop: '1rem',
    },
    p: {
        marginBottom: '1rem'
    },
    picfLink: {
        color: theme.palette.secondary.main,
        fontSize: '1.25rem'
    },
    link: {
        color: theme.palette.secondary.main,
    },
    list: {
        padding: theme.spacing(0)
    },
    listIcon: {
        color: theme.palette.secondary.main,
        minWidth: '20px'
    },
    formContainer: {
        display: "flex",
        justifyContent: "center",
    },
    formSection: {
        backgroundColor: "#ece7f1",
        margin: theme.spacing(3, 0),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(3),
    },
    formGrid: {
        '& .MuiGrid-item': {
            padding: theme.spacing(0, 2)
        },
        display: "flex",
        justifyContent: "center"
    },
    fieldGroupLabel: {
        fontSize: '1.050rem',
        fontWeight: 'bold',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    label: {
        fontSize: '1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'center',
    },
    submitBtn: {
        borderRadius: 15,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: 'white'
        }
    },
    errorMessage: {
        color: 'red',
        padding: '0.15rem'
    },
    disableMessage: {
        color: theme.palette.secondary.main,
    },
    submitSuccess: {
        backgroundColor: "#ece7f1",
        color: theme.palette.primary.main,
        margin: theme.spacing(3, 0),
        padding: theme.spacing(5),
    },
    submitSuccessMessage: {
        fontSize: '2.75rem',
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    submitSuccessIcon: {
        color: theme.palette.secondary.main,
        fontSize: '5rem'
    },
    investigatorSection: {
        backgroundColor: "#ece7f1",
        margin: theme.spacing(3, 0),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    dialogTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    dialogContent: {
        padding: theme.spacing(2, 3)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const ErrorMessage: React.FunctionComponent<{message: string}> = ({ message }) => {
    const classes = useStyles();

    return (
        <div className={classes.errorMessage}>
            <FontAwesomeIcon icon={faExclamationCircle} />
            <span style={{ marginLeft: "0.5rem" }}>{message}</span>
        </div>
    );
}

const PatientConsentVerificationDialog: React.FunctionComponent<IPatientConsentVerificationDialogProps> = ({
    open,
    patientConsent,
    setOpen,
    setConsentSubmitted
}) => {
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const client = onlinePatientManagement.serviceStackClient;

    const [verificationCode, setVerificationCode] = React.useState<number | undefined>(undefined);

    const [loading, setLoading] = React.useState(false);

    const [codeSent, setCodeSent] = React.useState(false);

    const [codeVerified, setCodeVerified] = React.useState(false);

    const handleMobileNumberSubmit = React.useCallback((mobileNumber) => {
        setLoading(true);

        //setLoading(false);
        //setCodeSent(true);
        //setVerificationCode(1234);

        //send code to contact number
        client
            .post(new Dtos.PatientConsentSendVerificationCode({
                phoneNumber: mobileNumber?.replaceAll(" ", "")
            }))
            .then(response => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Verification Code Sent
                        </AlertTitle>
                        The verification code was sent successfully.
                    </>,
                    { variant: 'success' }
                );
                setLoading(false);
                setCodeSent(true);
                setVerificationCode(response.verificationCode);
                
            })
            .catch((e) => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Verification Code Not Sent
                        </AlertTitle>
                        An error occurred while sending the verification code.
                    </>,
                    { variant: 'critical' }
                );
                setLoading(false);
                setOpen(false);
            })

    }, [client, enqueueSnackbar, setCodeSent, setLoading, setVerificationCode, setOpen]);

    const handleCodeVerify = React.useCallback((code) => {
        setLoading(true);

        //check if the code matches the verification sent to patient
        if (!code || +code !== verificationCode) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Code Not Valid
                    </AlertTitle>
                    The code must match the code sent to your mobile number.
                </>,
                { variant: 'critical' }
            );
        } else {
            //code verified
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Code Verified
                    </AlertTitle>
                    The code vas verified successfully.
                </>,
                { variant: 'success' }
            );
            setCodeVerified(true);
        }

        setLoading(false);
    }, [enqueueSnackbar, patientConsent, verificationCode, setCodeVerified, setLoading, setOpen]);

    const handleConsentSubmit = React.useCallback((mobileNumber) => {
        setLoading(true);

        const updatedPatientConsent = new Dtos.PatientConsent(patientConsent);

        updatedPatientConsent.mobileNumber = mobileNumber?.replaceAll(" ", "");

        client
            .post(new Dtos.PatientConsentPostSave({
                patientConsent: updatedPatientConsent,
                emailRequired: true
            }))
            .then(response => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Consent Submitted
                        </AlertTitle>
                        The consent was submitted successfully.
                    </>,
                    { variant: 'success' }
                );
                setLoading(false);
                setOpen(false);
                setConsentSubmitted(true);
            })
            .catch((e) => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Consent Not Submitted
                        </AlertTitle>
                        An error occurred while trying to submit the consent. Please contact your system administrator.
                    </>,
                    { variant: 'critical' }
                );
                setLoading(false);
                setOpen(false);
            });
    }, [client, enqueueSnackbar, patientConsent, setConsentSubmitted, setOpen, setLoading]);

    const onSubmit = React.useCallback((data) => {
        if (!codeSent) {
            handleMobileNumberSubmit(data.mobileNumber);
        } else if (codeSent && !codeVerified) {
            handleCodeVerify(data.code);
        } else if (codeVerified) {
            handleConsentSubmit(data.mobileNumber)
        }
    }, [codeSent, codeVerified, handleCodeVerify, handleMobileNumberSubmit]);

    const { register, handleSubmit, setValue, watch, trigger, formState: { errors, isSubmitting } } = useForm();

    const mobileNumber = watch("mobileNumber");

    return (
        <>
            <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="dialog" maxWidth="sm">
                <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle
                    id="dialog-title"
                    className={classes.dialogTitle}
                >
                    {!codeVerified ? "Verify your identity" : "Verification Successful" }
                </DialogTitle>
                <DialogContent
                    className={classes.dialogContent}
                >
                    {
                        !codeSent && <>
                           <DialogContentText>Please enter your mobile number:</DialogContentText>
                            <div style={{ marginTop: '1rem' }}>
                                <MuiPhoneNumber
                                    defaultCountry={"au"}
                                    onlyCountries={["au"]}
                                        onChange={(value) => { setValue("mobileNumber", value); trigger(); }}
                                    variant={"outlined"}
                                    label={"Mobile Number"}
                                    fullWidth
                                />
                                <TextField
                                    variant={"outlined"}
                                    label={"Mobile Number"}
                                    fullWidth
                                    style={{ display: "none" }}
                                        {...register("mobileNumber", { required: !codeSent, pattern: /^\+61\s\d{3}\s\d{3}\s\d{3}$/i })}
                                />
                                {errors.mobileNumber?.type === "required" && <ErrorMessage message={"This field is required"} />}
                                {errors.mobileNumber?.type === "pattern" && <ErrorMessage message={"This field must be a valid mobile number e.g. +61 xxx xxx xxx"} />}
                            </div>
                        </>
                    }

                    {
                        !!codeSent && !codeVerified && <>
                            <DialogContentText>Please enter the code we sent to +61 xxx xxx {mobileNumber?.substr(mobileNumber?.length - 3)}:</DialogContentText>
                            <div style={{ marginTop: '1rem' }}>
                                <TextField
                                    variant={"outlined"}
                                    label={"Verification Code"}
                                    fullWidth
                                        {...register("code", { required: codeSent, pattern: /^\d{4}$/i })}
                                />
                                {errors.code?.type === "required" && <ErrorMessage message={"This field is required"} />}
                                {errors.code?.type === "pattern" && <ErrorMessage message={"This field must have 4 digits"} />}
                            </div>
                        </>
                    }

                    {
                        !!codeVerified && <DialogContentText>
                            Verification was successful. You can now submit the consent form.
                        </DialogContentText>
                    }
                </DialogContent>
                <DialogActions>
                    <ProgressButton
                        loading={loading}
                        color="secondary"
                        onClick={() => setOpen(false)}
                    >
                        Cancel
                    </ProgressButton>
                    {
                        !codeSent && <ProgressButton
                            type="submit"
                            loading={loading}
                            color="primary"
                            disabled={false}
                        >
                            Continue
                        </ProgressButton>
                    }
                    {
                        !!codeSent && !codeVerified && <ProgressButton
                            type={"submit"}
                            loading={loading}
                            color="primary"
                            disabled={false}
                        >
                            Verify
                        </ProgressButton>
                    }
                    {
                        codeVerified && <ProgressButton
                            type={"submit"}
                            loading={loading}
                            color="primary"
                            disabled={false}
                        >
                            Submit
                        </ProgressButton>
                    }
                </DialogActions>
                </form>
            </Dialog>
        </>
    );
}

const PatientConsentField: React.FunctionComponent<IPatientConsentFieldProps> = ({
    fieldGroupLabel,
    label,
    inputComponent
}) => {
        const classes = useStyles();

        return (
            <>
                {
                    !!fieldGroupLabel && (
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={11}
                            lg={11}
                            xl={11}
                        >
                            <Typography
                                variant="h2"
                                className={classes.fieldGroupLabel}
                            >
                                {fieldGroupLabel}
                            </Typography>
                        </Grid>    
                    )
                }
                {
                    !!label && (
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={3}
                            lg={2}
                            xl={2}
                            className={classes.label}
                        >
                            {label}
                        </Grid>    
                    )
                }
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={!!label ? 8 : 11}
                    lg={!!label ? 9 : 11}
                    xl={!!label ? 9 : 11}
                >
                    {inputComponent}
                </Grid>
            </>
        );
    }

const PatientConsentForm: React.FunctionComponent<IPatientConsentFormProps> = ({
    isInvestigator,
    patientConsent,
    picfVersion,
    setConsentSubmitted
}) => {
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const client = onlinePatientManagement.serviceStackClient;

    const [verificationDialogOpen, setVerificationDialogOpen] = React.useState(false);

    const [updatedPatientConsent, setUpdatedPatientConsent] = React.useState<Dtos.PatientConsent>();

    const convertStrToBoolean = React.useCallback((str?: string) => {
        switch (str) {
            case "1": return true; break;
            case "0": return false; break;
            default: return undefined;
        }
    }, []);

    const convertBooleanToStr = React.useCallback((bool?: boolean) => {
        switch (bool) {
            case true: return "1"; break;
            case false: return "0"; break;
            default: return null;
        }
    }, []);

    const defaultValues = React.useMemo(() => {
        if (isInvestigator) {
            return {
                patientSigned: convertBooleanToStr(patientConsent?.patientSigned),
                contactAboutGeneticRisks: convertBooleanToStr(patientConsent?.contactAboutGeneticRisks),
                alternativeContactName: patientConsent?.alternativeContactName,
                alternativeContactAddress: patientConsent?.alternativeContactAddress,
                alternativeContactNumber: patientConsent?.alternativeContactNumber,
                alternativeContactEmail: patientConsent?.alternativeContactEmail,
                storeTissueAndBloodSamples: convertBooleanToStr(patientConsent?.storeTissueAndBloodSamples),
                storeImages: convertBooleanToStr(patientConsent?.storeImages)
            }
        }

        return {
            patientSigned: null as any,
            contactAboutGeneticRisks: null as any,
            alternativeContactName: null as any,
            alternativeContactAddress: null as any,
            alternativeContactNumber: null as any,
            alternativeContactEmail: null as any,
            storeTissueAndBloodSamples: null as any,
            storeImages: null as any
        }
    }, [convertBooleanToStr, isInvestigator, patientConsent])

    const { register, handleSubmit, watch, setValue, reset, trigger, formState: { errors, isSubmitting } } = useForm<Dtos.PatientConsent>({
        defaultValues: defaultValues
    });

    const onPatientSignedChanged = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if ((event.target as HTMLInputElement).value === "0") {
            setValue("contactAboutGeneticRisks", null);
            setValue("alternativeContactName", null);
            setValue("alternativeContactAddress", null);
            setValue("alternativeContactNumber", null);
            setValue("alternativeContactEmail", null);
            setValue("storeTissueAndBloodSamples", null);
            setValue("storeImages", null);
        }

        if (Object.keys(errors).length > 0) {
            trigger();
        }
    }, [errors, setValue, trigger]);

    const onPatientAgreedBeingContactAboutGeneticRisksChanged = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if ((event.target as HTMLInputElement).value === "0") {
            setValue("alternativeContactName", null);
            setValue("alternativeContactAddress", null);
            setValue("alternativeContactNumber", null);
            setValue("alternativeContactEmail", null);
        }

        if (Object.keys(errors).length > 0) {
            trigger();
        }
    }, [errors, setValue, trigger]);

    const patientNotParticipating = (watch("patientSigned") === "0");

    const patientAgreedBeingContactAboutGeneticRisks = (watch("contactAboutGeneticRisks") === "1");

    const onSubmit = React.useCallback((data) => {
        const updatedPatientConsent = new Dtos.PatientConsent(patientConsent);

        if (isInvestigator) {
            if (!patientConsent || patientConsent.statusId !== Dtos.StatusTypeEnum.InvestigatorSigning) {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Consent Not Submitted
                        </AlertTitle>
                        Consent does not have a valid status.
                    </>,
                    { variant: 'critical' }
                );

                return;
            }

            updatedPatientConsent.investigatorSigned = data.investigatorSigned;
            updatedPatientConsent.dateInvestigatorSigned = DateTime.local().toUTC().toString();
            updatedPatientConsent.statusId = Dtos.StatusTypeEnum.Completed;
            updatedPatientConsent.version = picfVersion;

            client
                .post(new Dtos.PatientConsentPostSave({
                    patientConsent: updatedPatientConsent,
                    emailRequired: true
                }))
                .then(response => {
                    enqueueSnackbar(
                        <>
                            <AlertTitle>
                                Consent Submitted
                            </AlertTitle>
                            The consent was submitted successfully.
                        </>,
                        { variant: 'success' }
                    );
                    setConsentSubmitted(true);
                })
                .catch((e) => {
                    enqueueSnackbar(
                        <>
                            <AlertTitle>
                                Consent Not Submitted
                            </AlertTitle>
                            An error occurred while trying to submit the consent. Please contact your system administrator.
                        </>,
                        { variant: 'critical' }
                    );
                })
        } else {
            if (!patientConsent || patientConsent.statusId !== Dtos.StatusTypeEnum.PatientSigning) {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Consent Not Submitted
                        </AlertTitle>
                        Consent does not have a valid status.
                    </>,
                    { variant: 'critical' }
                );

                return;
            }

            updatedPatientConsent.version = picfVersion;
            updatedPatientConsent.patientSigned = convertStrToBoolean(data.patientSigned);
            updatedPatientConsent.datePatientSigned = DateTime.local().toUTC().toString();
            updatedPatientConsent.contactAboutGeneticRisks = convertStrToBoolean(data.contactAboutGeneticRisks);
            updatedPatientConsent.storeTissueAndBloodSamples = convertStrToBoolean(data.storeTissueAndBloodSamples);
            updatedPatientConsent.storeImages = convertStrToBoolean(data.storeImages);
            updatedPatientConsent.alternativeContactName = data.alternativeContactName;
            updatedPatientConsent.alternativeContactAddress = data.alternativeContactAddress;
            updatedPatientConsent.alternativeContactNumber = data.alternativeContactNumber;
            updatedPatientConsent.alternativeContactEmail = data.alternativeContactEmail;
            updatedPatientConsent.statusId = updatedPatientConsent.patientSigned ? Dtos.StatusTypeEnum.InvestigatorSigning : Dtos.StatusTypeEnum.Refused;

            if (updatedPatientConsent.typeId === Dtos.ConsentTypeEnum.FaceToFace) {
                setUpdatedPatientConsent(updatedPatientConsent);
                setVerificationDialogOpen(true);
            } else {
                client
                    .post(new Dtos.PatientConsentPostSave({
                        patientConsent: updatedPatientConsent,
                        emailRequired: true
                    }))
                    .then(response => {
                        enqueueSnackbar(
                            <>
                                <AlertTitle>
                                    Consent Submitted
                                </AlertTitle>
                                The consent was submitted successfully.
                            </>,
                            { variant: 'success' }
                        );
                        setConsentSubmitted(true);
                    })
                    .catch((e) => {
                        enqueueSnackbar(
                            <>
                                <AlertTitle>
                                    Consent Not Submitted
                                </AlertTitle>
                                An error occurred while submitting the consent.
                            </>,
                            { variant: 'critical' }
                        );
                    })
            }
        }
    }, [client, convertStrToBoolean, enqueueSnackbar, isInvestigator, patientConsent, picfVersion, setConsentSubmitted, setUpdatedPatientConsent, setVerificationDialogOpen]);

    //set radiogroup value
    const patientSignedValue = React.useMemo(() => {
        return isInvestigator ? defaultValues?.patientSigned : (watch("patientSigned") ?? null);
    }, [defaultValues, isInvestigator, watch("patientSigned")]);

    const contactAboutGeneticRisksValue = React.useMemo(() => {
        return isInvestigator ? defaultValues?.contactAboutGeneticRisks : (watch("contactAboutGeneticRisks") ?? null);
    }, [defaultValues, isInvestigator, watch("contactAboutGeneticRisks")]);

    const storeTissueAndBloodSamplesValue = React.useMemo(() => {
        return isInvestigator ? defaultValues?.storeTissueAndBloodSamples : (watch("storeTissueAndBloodSamples") ?? null);
    }, [defaultValues, isInvestigator, watch("storeTissueAndBloodSamples")]);

    const storeImagesValue = React.useMemo(() => {
        return isInvestigator ? defaultValues?.storeImages : (watch("storeImages") ?? null);
    }, [defaultValues, isInvestigator, watch("storeImages")]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Typography
                    variant="h2"
                    className={classes.subtitle}
                >
                    Main Study Consent
                </Typography>
                <div className={classes.formSection}>
                    <Grid
                        container
                        spacing={2}
                        className={classes.formGrid}
                        >
                        <PatientConsentField
                            fieldGroupLabel={"Declaration by Participant"}
                            inputComponent={
                                <>
                                    <RadioGroup value={patientSignedValue}>
                                        <FormControlLabel
                                            label={<><strong>I AGREE</strong> to participate in the clinical trial</>}
                                            value="1"
                                            control={
                                                <Radio
                                                    disabled={isInvestigator}
                                                    {...register("patientSigned", { required: !isInvestigator, onChange: onPatientSignedChanged })}
                                                />
                                            }
                                        />
                                        <FormControlLabel
                                            label={<><strong>I DO NOT agree</strong> to participate in the clinical trial</>}
                                            value="0"
                                            control={
                                                <Radio
                                                    disabled={isInvestigator}
                                                    {...register("patientSigned", { required: !isInvestigator, onChange: onPatientSignedChanged })}
                                                />
                                            }
                                        />
                                        {errors.patientSigned && <ErrorMessage message={"This field is required"} />}
                                        {patientNotParticipating && <span className={classes.disableMessage}>YOU HAVE CHOSEN NOT TO PARTICIPATE IN THE CLINICAL TRIAL</span>}
                                    </RadioGroup>
                                </>
                            }
                        />
                        <PatientConsentField
                            fieldGroupLabel={"Please select one of the following regarding the tests that may show information about genetic risks of cancer:"}
                            inputComponent={
                                <>
                                    <RadioGroup value={contactAboutGeneticRisksValue}>
                                        <FormControlLabel
                                            label={<><strong>I AGREE</strong> to being contacted if tests show information about genetic risks of cancer</>}
                                            value="1"
                                            control={
                                                <Radio
                                                    disabled={patientNotParticipating || isInvestigator}
                                                    {...register("contactAboutGeneticRisks", { required: !patientNotParticipating && !isInvestigator, onChange: onPatientAgreedBeingContactAboutGeneticRisksChanged })}
                                                />
                                            }
                                        />
                                        {
                                             !!patientAgreedBeingContactAboutGeneticRisks && <Grid
                                                container
                                                spacing={2}
                                                className={classes.formGrid}
                                             >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={11}
                                                    lg={11}
                                                    xl={11}
                                                >
                                                    <Typography
                                                        variant="body1"
                                                        variantMapping={{
                                                            body1: 'p'
                                                        }}
                                                        className={classes.p}
                                                        style={{ marginTop: '1rem' }}
                                                    >
                                                        If I cannot be contacted, I want a relative to be told about any research results that may be medically important to my family. I have/will tell this person that I have nominated them:
                                                </Typography>
                                                </Grid>
                                                <PatientConsentField
                                                    label={"Name"}
                                                    inputComponent={
                                                        <>
                                                            <TextField
                                                                variant="outlined"
                                                                color="primary"
                                                                margin="dense"
                                                                disabled={!patientAgreedBeingContactAboutGeneticRisks || isInvestigator}
                                                                fullWidth
                                                                {...register("alternativeContactName")}
                                                            />
                                                        </>
                                                    }
                                                />
                                                <PatientConsentField
                                                    label={"Address"}
                                                    inputComponent={
                                                        <>
                                                            <TextField
                                                                variant="outlined"
                                                                color="primary"
                                                                margin="dense"
                                                                disabled={!patientAgreedBeingContactAboutGeneticRisks || isInvestigator}
                                                                fullWidth
                                                                {...register("alternativeContactAddress")}
                                                            />
                                                        </>
                                                    }
                                                />
                                                <PatientConsentField
                                                    label={"Contact Number"}
                                                    inputComponent={
                                                        <>
                                                            <TextField
                                                                variant="outlined"
                                                                color="primary"
                                                                margin="dense"
                                                                disabled={!patientAgreedBeingContactAboutGeneticRisks || isInvestigator}
                                                                fullWidth
                                                                {...register("alternativeContactNumber")}
                                                            />
                                                        </>
                                                    }
                                                />
                                                <PatientConsentField
                                                    label={"Email"}
                                                    inputComponent={
                                                        <>
                                                            <TextField
                                                                variant="outlined"
                                                                color="primary"
                                                                margin="dense"
                                                                disabled={!patientAgreedBeingContactAboutGeneticRisks || isInvestigator}
                                                                fullWidth
                                                                {...register("alternativeContactEmail")}
                                                            />
                                                        </>
                                                    }
                                                />
                                            </Grid>
                                        }
                                        <FormControlLabel
                                            label={<><strong>I DO NOT agree</strong> being contacted if tests show information about genetic risks of cancer</>}
                                            value="0"
                                            control={
                                                <Radio
                                                    disabled={patientNotParticipating || isInvestigator}
                                                    {...register("contactAboutGeneticRisks", { required: !patientNotParticipating && !isInvestigator, onChange: onPatientAgreedBeingContactAboutGeneticRisksChanged })}
                                                />
                                            }
                                        />
                                        {errors.contactAboutGeneticRisks && <ErrorMessage message={"This field is required"} />}
                                    </RadioGroup>
                                </>
                            }
                        />
                    </Grid>
                </div>

                <Typography
                    variant="h2"
                    className={classes.subtitle}
                >
                    Optional Consent
                </Typography>
                <div className={classes.formSection}>
                    <Grid
                        container
                        spacing={2}
                        className={classes.formGrid}
                    >
                        <PatientConsentField
                            fieldGroupLabel={"Please select one of the following regarding the storage and use of all of your tissue and blood samples collected as part of FINER for future unspecified research:"}
                            inputComponent={
                                <>
                                    <RadioGroup value={storeTissueAndBloodSamplesValue}>
                                        <FormControlLabel
                                            label={<><strong>I AGREE</strong> to the storage of my tissue and blood samples collected as part of FINER for future unspecified research</>}
                                            value="1"
                                            control={
                                                <Radio
                                                    disabled={patientNotParticipating || isInvestigator}
                                                    {...register("storeTissueAndBloodSamples", { required: !patientNotParticipating && !isInvestigator})}
                                                />
                                            }
                                        />
                                        <FormControlLabel
                                            label={<><strong>I DO NOT agree</strong> to the storage of my tissue and blood samples collected as part of FINER for future unspecified research</>}
                                            value="0"
                                            control={
                                                <Radio
                                                    disabled={patientNotParticipating || isInvestigator}
                                                    {...register("storeTissueAndBloodSamples", { required: !patientNotParticipating && !isInvestigator})}
                                                />
                                            }
                                        />
                                        {errors.storeTissueAndBloodSamples && <ErrorMessage message={"This field is required"} />}
                                    </RadioGroup>
                                </>
                            }
                        />
                        <PatientConsentField
                            fieldGroupLabel={"Please select one of the following regarding the storage and use your images (scans, photos) collected as part of FINER for future unspecified research:"}
                            inputComponent={
                                <>
                                    <RadioGroup value={storeImagesValue}>
                                        <FormControlLabel
                                            label={<><strong>I AGREE</strong> to the storage of my images (scans, photos) collected as part of FINER for future unspecified research</>}
                                            value="1"
                                            control={
                                                <Radio
                                                    disabled={patientNotParticipating || isInvestigator}
                                                    {...register("storeImages", { required: !patientNotParticipating && !isInvestigator })}
                                                />
                                            }
                                        />
                                        <FormControlLabel
                                            label={<><strong>I DO NOT agree</strong> to the storage of my images (scans, photos) collected as part of FINER for future unspecified research</>}
                                            value="0"
                                            control={
                                                <Radio
                                                    disabled={patientNotParticipating || isInvestigator}
                                                    {...register("storeImages", { required: !patientNotParticipating && !isInvestigator })}
                                                />
                                            }
                                        />
                                        {errors.storeImages && <ErrorMessage message={"This field is required"} />}
                                    </RadioGroup>
                                </>
                            }
                        />
                    </Grid>
                </div>

                {
                    !!isInvestigator && (
                        <>
                            <Typography
                                variant="h2"
                                className={classes.subtitle}
                            >
                                        Declaration by Study Doctor/Senior Researcher
                            </Typography>
                            <div className={classes.investigatorSection}>
                                <Grid
                                    container
                                    spacing={2}
                                    className={classes.formGrid}
                                >
                                    <PatientConsentField
                                        inputComponent={
                                            <>
                                                <FormGroup>
                                                    <div>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    {...register("investigatorSigned", { required: !!isInvestigator })}
                                                                />
                                                            }
                                                            label="I have given a verbal explanation of the study, its procedures and risks and I believe that the participant has understood that explanation."
                                                        />
                                                        {errors.investigatorSigned && <ErrorMessage message={"This field is required"} />}
                                                    </div>
                                                </FormGroup>
                                            </>
                                        }
                                    />
                                </Grid>
                            </div>
                        </>
                    )
                }

                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '1.5rem'}}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submitBtn}
                        disabled={isSubmitting}
                    >
                            Submit
                    </Button>
                </div>
            </form>

            {
                verificationDialogOpen && <PatientConsentVerificationDialog
                    open={verificationDialogOpen}
                    patientConsent={updatedPatientConsent}
                    setOpen={setVerificationDialogOpen}
                    setConsentSubmitted={setConsentSubmitted}
                />
            }
        </>
    );
}

const TrialConsentPage: React.FunctionComponent<ITrialConsentPageProps> = () => {
    const classes = useStyles();

    const history = useHistory();

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const client = onlinePatientManagement.serviceStackClient;

    const isInvestigator = React.useMemo(() => {
        if (history?.location?.pathname?.startsWith("/public/trial-consent-signed/")) {
            return true;
        }

        return false;
    }, [history.location.pathname]);

    const params = useParams<ITrialConsentPageParams>();

    const consentGuid = params?.guid;

    const [consentSubmitted, setConsentSubmitted] = React.useState(false);

    const [picfVersion, setPicfVersion] = React.useState<string>("");

    const [institutionName, setInstitutionName] = React.useState<string>("");

    const [patientConsent, setPatientConsent] = React.useState<Dtos.PatientConsent | undefined>(undefined);

    const { enqueueSnackbar } = useSnackbar();

    //load the patient consent using the guid in the url
    React.useEffect(() => {
        client
            .get(new Dtos.PatientConsentGetSingleByGuid({
                guid: consentGuid
            }))
            .then(response => {
                setPatientConsent(response.patientConsent);
            })
            .catch((e) => {

            });
    }, [client, consentGuid, setPatientConsent]);

    //load the pic using the invitation guid
    React.useEffect(() => {
        if (patientConsent?.eoi?.invitation?.guid) {
            client
                .get(new Dtos.InvitationGetPicf({
                    guid: patientConsent?.eoi?.invitation?.guid
                }))
                .then(response => {
                    setPicfVersion(response.version);
                    setInstitutionName(response.institutionName);
                })
                .catch((e) => {
                    enqueueSnackbar(
                        <>
                            <AlertTitle>
                                Error
                        </AlertTitle>
                        An error occurred while trying to retrieve the PIC file. Please contact your system administrator.
                        </>,
                        { variant: 'critical' }
                    );
                });
        }
    }, [client, enqueueSnackbar, patientConsent, setPicfVersion, setInstitutionName]);

    const picDownloadLink = `/opms/invitation/download/picf/${patientConsent?.eoi?.invitation?.guid}`;

    const onPicDownloadClick = React.useCallback(() => {
        client
            .get(new Dtos.InvitationGetPicf({
                guid: patientConsent?.eoi?.invitation?.guid
            }))
            .then(response => {
                var link = document.createElement('a');
                link.href = picDownloadLink
                link.click();
                link.remove();

                setPicfVersion(response?.version);
            })
            .catch((e) => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Error
                        </AlertTitle>
                        An error occurred while trying to download the PIC file. Please contact your system administrator.
                    </>,
                    { variant: 'critical' }
                );
            })
    }, [enqueueSnackbar, patientConsent, setPicfVersion, picDownloadLink]);

    if (!patientConsent) {
        return (
            <RouteLoading />
        )
    }

    return (
        <>
            <div className={classes.container}>
                <Typography
                    variant="h1"
                    className={classes.title}
                >
                    <strong>Participant Information and Consent</strong>
                </Typography>
                <Typography
                    variant="body1"
                    variantMapping={{
                        body1: 'p'
                    }}
                    className={classes.p}
                >
                    You are invited to take part in a study called “<strong>F</strong>ulvestrant and <strong>I</strong>patasertib in advanced HER2-<strong>N</strong>egative <strong>ER</strong>-positive breast cancer”, 
                    (which will be referred to as <strong>‘FINER’</strong>) because you have advanced or metastatic breast cancer that is oestrogen-receptor (ER)-positive and human epidermal growth factor receptor 2 (HER2)-negative.
                    The cancer has grown or spread despite receiving the usual initial treatment for this type of cancer with drugs like ribociclib or palbociclib, and letrozole, anastrozole or exemestane.
                </Typography>
                <Typography
                    variant="body1"
                    variantMapping={{
                        body1: 'p'
                    }}
                    className={classes.p}
                >
                    This Participant Information Sheet/Consent Form tells you about the research study. It explains the tests and treatments involved, to help you decide if you want to take part. Please read this information carefully. Ask questions about anything that you don’t understand or want to know more about. Before deciding whether or not to take part, you might want to talk to a relative, friend and/or your local doctor.
                </Typography>
                <Typography
                    variant="body1"
                    variantMapping={{
                        body1: 'p'
                    }}
                    className={classes.p}
                >
                    If you don’t want to take part in this study, you don’t have to. You will receive the best possible care whether or not you take part. If you decide to take part, you can stop at any time if you change your mind.
                </Typography>
                <Typography
                    variant="body1"
                    variantMapping={{
                        body1: 'p'
                    }}
                    style={{ textAlign: "center" }}
                >
                    {
                        picfVersion && <Link onClick={onPicDownloadClick} target="_blank" rel="noopener" className={classes.picfLink}>Participant Informed Consent (PIC) {picfVersion} ({institutionName})</Link>
                    }
                </Typography>
                <List className={classes.list}>
                    <ListItem>
                        <ListItemIcon className={classes.listIcon}>
                            <FontAwesomeIcon icon={faAngleRight} />
                        </ListItemIcon> 
                        <ListItemText>
                            I have read the attached Participant Information Sheet or someone has read it to me in a language that I understand.
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon className={classes.listIcon}>
                            <FontAwesomeIcon icon={faAngleRight} />
                        </ListItemIcon> 
                        <ListItemText>
                            I understand the nature and purpose of the research study and I understand what I am being asked to do.
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon className={classes.listIcon}>
                            <FontAwesomeIcon icon={faAngleRight} />
                        </ListItemIcon> 
                        <ListItemText>
                            I have discussed my participation in this study with the member of the study team named below.
                            I have had the opportunity to ask questions and I am satisfied with the answers I have received.
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon className={classes.listIcon}>
                            <FontAwesomeIcon icon={faAngleRight} />
                        </ListItemIcon> 
                        <ListItemText>
                            I have been  told about the possible risks of taking part in this study.
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon className={classes.listIcon}>
                            <FontAwesomeIcon icon={faAngleRight} />
                        </ListItemIcon> 
                        <ListItemText>
                            I consent to my doctors, other health professionals, hospitals or laboratories outside this institution releasing information
                            about my condition and treatment which is needed for this study and understand that such information will remain confidential.
                            I understand that my usual doctor (GP) will be informed of my participation in this study.
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon className={classes.listIcon}>
                            <FontAwesomeIcon icon={faAngleRight} />
                        </ListItemIcon> 
                        <ListItemText>
                            I freely consent to participate in the research study as described in the attached Participant Information Sheet.
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon className={classes.listIcon}>
                            <FontAwesomeIcon icon={faAngleRight} />
                        </ListItemIcon> 
                        <ListItemText>
                            I understand that my participation is voluntary and that I am free to withdraw at any time during the study without affecting
                            my future health care.
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon className={classes.listIcon}>
                            <FontAwesomeIcon icon={faAngleRight} />
                        </ListItemIcon> 
                        <ListItemText>
                            I understand that if I decide to stop study treatment, I may be asked to attend follow-up visits for collection
                            of information about my health status. Alternatively, the investigator/sponsor will request my permission to access my
                            medical records for collection of follow-up information for research and analysis.
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon className={classes.listIcon}>
                            <FontAwesomeIcon icon={faAngleRight} />
                        </ListItemIcon> 
                        <ListItemText>
                            I understand that the study sponsor (BCT) may ask for details about me from this institution (such as name, gender,
                            date of birth, address) for use in future data linkage. I understand that requests for data linkage will only occur after
                            approval, BCT will only give my identifiable information to the data custodian(s) for data linkage, and the data custodian(s)
                            will de-identify my information before giving it to researchers.
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon className={classes.listIcon}>
                            <FontAwesomeIcon icon={faAngleRight} />
                        </ListItemIcon> 
                        <ListItemText>
                            I consent to the storage and use of my tumour samples, blood samples and images (scans and photos) taken for use as described
                            in the Participant Information Sheet.
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon className={classes.listIcon}>
                            <FontAwesomeIcon icon={faAngleRight} />
                        </ListItemIcon> 
                        <ListItemText>
                            I understand that I will be given a signed copy of this document to keep.
                        </ListItemText>
                    </ListItem>
                </List>

                {
                    !consentSubmitted && (
                        <PatientConsentForm
                            isInvestigator={isInvestigator}
                            patientConsent={patientConsent}
                            picfVersion={picfVersion}
                            setConsentSubmitted={setConsentSubmitted}
                        />
                    ) 
                }

                {
                    !!consentSubmitted && (
                        <div className={classes.submitSuccess}>
                            <Grid
                                container
                                spacing={2}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={2}
                                    lg={1}
                                    xl={1}
                                    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                >
                                    <FontAwesomeIcon icon={faCheckCircle} className={classes.submitSuccessIcon} />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={10}
                                    lg={11}
                                    xl={11}
                                >
                                    <div className={classes.submitSuccessMessage}>
                                        <span>Thank you for completing <br /> the consent form</span>
                                    </div>
                                    {
                                        (!!isInvestigator || patientConsent?.typeId == Dtos.ConsentTypeEnum.FaceToFace) && (
                                            <span>
                                                Check and manage the consents <Link href={"/econsent"} target="_blank" className={classes.link}>here</Link>.
                                            </span>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    )
                }
            </div>
        </>  
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default TrialConsentPage;